import { config as appConfig } from "../../../app.config"

const portalSettings = {
  path: "settings",
  component: () => import("@/views/Portal/Settings"),
  meta: {
    pageTitle: "Portal settings",
    rule: "portalAdmin",
  },
  children: [
    {
      path: "",
      name: "portal.settings.general",
      component: () => import("@/views/Portal/PortalSettings/PortalSettingsGeneral"),
      meta: {
        pageTitle: "Portal settings",
        parent: "portal.settings",
        rule: "portalAdmin",
      },
    },
  ],
}

if (appConfig.isZeno) {
  portalSettings.children.push({
    path: "notifications",
    name: "portal.notifications",
    component: () => import("@/views/Portal/PortalSettings/PortalNotifications.vue"),
    meta: {
      pageTitle: "Notifications",
      parent: "portal.settings",
      rule: "portalAdmin",
    },
  })

  portalSettings.children.push({
    path: "changelog",
    name: "portal.changelog",
    component: () => import("@/views/Portal/PortalChangelog/PortalChangelog"),
    meta: {
      pageTitle: "Changelog",
      parent: "portal.settings",
      rule: "portalAdmin",
    },
  })

  portalSettings.children.push({
    path: "posts",
    name: "portal.posts",
    component: () => import("@/views/Portal/PortalPosts/PortalPosts"),
    meta: {
      pageTitle: "Posts",
      parent: "portal.settings",
      rule: "portalAdmin",
    },
  })

  portalSettings.children.push({
    path: "posts/create",
    name: "portal.posts.create",
    component: () => import("@/views/Portal/PortalPosts/PortalPostCreate"),
    meta: {
      pageTitle: "Create",
      parent: "portal.posts",
      rule: "portalAdmin",
    },
  })
  portalSettings.children.push({
    path: "posts/:postKey/edit",
    name: "portal.posts.edit",
    component: () => import("@/views/Portal/PortalPosts/PortalPostEdit"),
    meta: {
      pageTitle: "Edit",
      parent: "portal.posts",
      rule: "portalAdmin",
    },
  })
  
}

export default [portalSettings]
