import * as api from "@/api/stationAutoDj.api"

export const fetchAutoDj = async ({ commit }, stationKey) => {
  commit("setLoadingAutoDj", true)

  const autoDj = await api.getAutoDj(stationKey)

  commit("setAutoDj", autoDj)

  commit("setLoadingAutoDj", false)
}

export const reorderPlaylist = async ({ commit }, { stationKey, references }) => {
  await api.updatePlaylistTracksOrder(stationKey, references)

  commit("setPlaylist", references)
}

export const changePreviewURL = async ({ commit }, url) => {
  commit("setPreviewURL", url)
}

export const shufflePlaylist = async ({ commit }, { stationKey, shuffle }) => {
  await api.updatePlaylistShuffle(stationKey, shuffle)

  commit("setShuffle", shuffle)
}

export const fetchCurrentlyPlaying = async ({ commit }, stationKey) => {
  const current = await api.getCurrentlyPlaying(stationKey)

  commit("setCurrent", current)
}

export const deleteTracks = async (vuex, { stationKey, references }) => {
  await api.deletePlaylistTracks(stationKey, references)

  const playlist = vuex.state.autoDj.playlist.filter((reference) => !references.includes(reference))

  vuex.commit("setPlaylist", playlist)
}

export const addPlaylistReferences = async ({ state, commit }, references) => {
  commit("setPlaylist", state.autoDj.playlist.concat(references))
}

export const resetAutoDj = async ({ commit }) => {
  commit("reset")
}
