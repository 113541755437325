import {initialState} from "./state"

export const setHasFinanceData = (state, payload) => {
  state.hasFinanceData = payload
}

export const setFinanceData = (state, payload) => {
  state.financeData = payload
}

export const setInvoiceSettings = (state, payload) => {
  state.invoiceSettings = payload
}

export const changeCustomTimeFrame = (state, payload) => {
  state.customTimeFrame = payload
}

export const changeActiveDataType = (state, value) => {
  state.dataTypes.forEach(dataType => {
    dataType.selected = (dataType.value === value)
  })
}

export const changeActiveTimeFrame = (state, value) => {
  state.timeFrames.forEach(timeFrame => {
    timeFrame.selected = (timeFrame.value === value)
  })
}

export const changeActiveDataFrequency = (state, value) => {
  state.dataFrequencies.forEach(dataFrequency => {
    dataFrequency.selected = (dataFrequency.value === value)
  })
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach(key => {
    state[key] = s[key]
  })
}
