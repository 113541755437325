<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="app" :class="vueAppClasses">
    <router-view @setAppClasses="setAppClasses" />

    <a
      v-if="pingParrotEnabled && showPingParrot"
      :href="'https://app.pingparrot.com/embed/' + pingParrotId"
      target="_blank"
      rel="nofollow"
      class="fixed right-0 bottom-0 p-3 text-white rounded-full flex"
      style="background-color: #f63d15; bottom: 82px !important; right: 18px !important">
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
      </svg>
    </a>
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js"
import { allowedAccounts, initializeIntercom, isIntercomEnabled, shutdownIntercom } from "@/services/IntercomService"
import { mapGetters } from "vuex"
import { isPingParrotEnabled, PINGPARROT_CHAT_ID } from "@/services/PingparrotService"

export default {
  data() {
    return {
      vueAppClasses: [],
      showPingParrot: false,
    }
  },

  computed: {
    ...mapGetters("AccountSubscriptions", ["accountSubscriptions"]),
    ...mapGetters("Account", ["accounts"]),

    pingParrotId() {
      return PINGPARROT_CHAT_ID
    },

    intercomEnabled() {
      return isIntercomEnabled()
    },

    pingParrotEnabled() {
      return isPingParrotEnabled()
    },
  },

  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val)
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr")
    },
    accounts() {
      this.showPingParrot = !this.accounts.length

      // if (!this.accounts.length) {
      // initializePingParrot()
      // }
    },
    accountSubscriptions: {
      handler() {
        if (this.accountSubscriptions !== null) {
          if (this.accountSubscriptions.length) {
            this.showPingParrot = false
            if (document.getElementById("bubble-btn")) {
              document.getElementById("bubble-btn").style.visibility = "hidden"
            }

            if (this.intercomEnabled) {
              initializeIntercom()
            }
          } else {
            if (this.intercomEnabled && allowedAccounts().includes(this.$route.params.accountKey)) {
              if (document.getElementById("bubble-btn")) {
                this.showPingParrot = false
                document.getElementById("bubble-btn").style.visibility = "hidden"
              }

              initializeIntercom()
            } else {
              shutdownIntercom()

              if (document.getElementById("bubble-btn")) {
                document.getElementById("bubble-btn").style.visibility = "visible"
              } else {
                // initializePingParrot()
                this.showPingParrot = true
              }
            }
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    toggleClassInBody(className) {
      if (className === "dark") {
        if (document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark")
        document.body.classList.add("theme-dark")
      } else if (className === "semi-dark") {
        if (document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark")
        document.body.classList.add("theme-semi-dark")
      } else {
        if (document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark")
        if (document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark")
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`)
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY)
    },
  },

  mounted() {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  },

  async created() {
    const dir = this.$vs.rtl ? "rtl" : "ltr"
    document.documentElement.setAttribute("dir", dir)

    window.addEventListener("resize", this.handleWindowResize)
    window.addEventListener("scroll", this.handleScroll)
  },

  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize)
    window.removeEventListener("scroll", this.handleScroll)
  },
}
</script>

<style>
.con-vs-dialog {
  z-index: 55000;
}

#chat-widget {
  height: calc(100% - 160px) !important;
}
</style>
