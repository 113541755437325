import AccountService from "@/services/AccountService";
import { getUserAccounts } from "@/services/UserService";

export const fetchAccounts = async ({ commit }) => {
  const accounts = await getUserAccounts();

  commit("setAccounts", accounts);

  return accounts;
};

export const fetchAccount = async ({ commit }, id) => {
  const account = await AccountService.getAccount(id);

  commit("setAccount", account);

  return account;
};

export const updateAccount = async ({ commit }, data) => {
  const account = await AccountService.updateAccount(data);

  commit("setAccount", account);

  return account;
};

export const suspendAccount = async ({ commit }, id) => {
  const account = await AccountService.suspendAccount(id);

  commit("setAccount", account);

  return account;
};

export const reactivateAccount = async ({ commit }, id) => {
  const account = await AccountService.reactivateAccount(id);

  commit("setAccount", account);

  return account;
};

export const deleteAccount = async ({ dispatch }, id) => {
  await AccountService.deleteAccount(id);

  dispatch("resetAccount");
};

export const resetAccount = async ({ commit }) => {
  commit("reset");
  commit("AccountPeople/reset", [], { root: true });
  commit("AccountStations/reset", [], { root: true });
  commit("AccountSubscriptions/reset", [], { root: true });
  commit("Station/reset", [], { root: true });
  commit("Analytics/reset", [], { root: true });
  commit("PodcastAnalytics/reset", [], { root: true });
  commit("Finance/reset", [], { root: true });
  commit("Episode/reset", [], { root: true });
  commit("Library/reset", [], { root: true });
  commit("Podcast/reset", [], { root: true });
  commit("Subscription/reset", [], { root: true });
};
