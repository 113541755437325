import { i18n } from "@/plugins/i18n";

export default [
  {
    path: "episodes",
    component: () => import("@/views/Podcasts/Episodes/PodcastEpisodes.vue"),
    children: [
      {
        path: "",
        name: "station.podcasts.episodes",
        component: () =>
          import("@/views/Podcasts/Episodes/PodcastEpisodeList.vue"),
        meta: {
          pageTitle: i18n.t("episodes.page_title"),
          parent: "stations",
          rule: "owner",
        },
      },
      {
        path: ":episodeKey",
        component: () => import("@/views/Podcasts/Episodes/PodcastEpisode.vue"),
        name: "station.podcasts.episode",
        props: true,
        meta: {
          pageTitle: "Episode info",
          parent: "stations",
          rule: "owner",
        },
        children: [
          {
            name: "station.podcasts.episode.edit",
            path: "edit",
            props: true,
            component: () =>
              import("@/views/Podcasts/Episodes/PodcastEpisodeEdit.vue"),
            meta: {
              pageTitle: "Edit episode",
              parent: "stations",
              rule: "owner",
            },
          },
        ],
      },
    ],
  },
];
