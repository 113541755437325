import toolsClient from "@/api/toolsClient"

export const getAll = async () => {
  const response = await toolsClient.get("/tools/posts")

  return response.data
}

export const create = async (data) => {
  const formData = new FormData()

  formData.append("post", JSON.stringify(data.formData))
  if (data.file) {
    formData.append("file", data.file)
  }

  const { response } = await toolsClient.post("/tools/posts", formData
)

  return response
}

export const update = async (data) => {
  const postKey = data.formData.key
  const formData = new FormData()

  formData.append("post", JSON.stringify(data.formData))
  if (data.file) {
    formData.append("file", data.file)
  }

  const { response } = await toolsClient.put(`/tools/posts/${postKey}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  return response

}

export const get = async (id) => {
  const response = await toolsClient.get("/tools/posts/" + id)

  return response.data
}

export const remove = async (id) => {
  const response = await toolsClient.delete("/tools/posts/" + id)

  return response.data
}
