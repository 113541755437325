import { libraryClient, uploaderClient } from "@/api/podcasts.api";

export const getEpisodes = async (podcastKey, { limit, offset, order }) => {
  const response = await libraryClient.get(
    `/api/podcasts/${podcastKey}/episodes?limit=${limit}&offset=${offset}&order=${order}`
  );

  return response.data;
};

export const searchEpisodes = async (podcastKey, search) => {
  const response = await libraryClient.post(
    `/api/podcasts/${podcastKey}/episodes/search`,
    { q: search }
  );

  return response.data;
};

export const getEpisode = async ({ podcastKey, episodeKey }) => {
  const response = await libraryClient.get(
    `/api/podcasts/${podcastKey}/episodes/${episodeKey}`
  );

  return response.data;
};

export const updateEpisode = async (episode) => {
  const response = await libraryClient.put(
    `/api/podcasts/${episode.podcast}/episodes/${episode.key}`,
    episode
  );

  return response.data;
};

export const deleteEpisode = async (episode) => {
  const response = await libraryClient.delete(
    `/api/podcasts/${episode.podcast}/episodes/${episode.key}`
  );

  return response.data;
};

export const uploadEpisodeFile = async (token, podcast, file) => {
  let data = new FormData();

  data.append("name", file.name);
  data.append("file", file);

  const response = await uploaderClient.post(
    `/upload/?token=${token.token}&folder=default&podcast=${podcast.key}&type=4`,
    data
  );

  return response.data;
};

export const getEpisodeUploadingStatus = async (task) => {
  const response = await uploaderClient.get(`/task/${task}`);

  return response.data;
};
