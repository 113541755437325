import {
  getAccountPeople,
  getAccountPeopleInvites,
} from "@/services/PeopleService";

export const fetchAccountPeople = async ({ commit }, accountKey) => {
  const people = await getAccountPeople(accountKey);

  commit("setPeople", people);
};

export const fetchAccountPeopleInvites = async ({ commit }, accountKey) => {
  const invites = await getAccountPeopleInvites(accountKey);

  commit("setInvites", invites);
};
