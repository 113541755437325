import toolsClient from "@/api/toolsClient";

export const getCountries = async (hasIso = false) => {
  let url = `stations/countries/list`;

  if (hasIso) {
    url += "?hasIso=true";
  }

  const response = await toolsClient.get(url);

  return response.data;
};
