import toolsClient from "@/api/toolsClient";

export const hasData = async (id) => {
  const { data } = await toolsClient.get(`accounts/${id}/hasFinancialData`);

  return data.hasFinancialData;
};

export const getData = async (id, type, params) => {
  let search = "";

  if (params !== undefined) {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        searchParams.append(key, params[key]);
      }
    });

    let searchString = searchParams.toString();

    search = searchString ? "?" + searchString : "";
  }

  let { data } = await toolsClient.get(
    `accounts/${id}/revenue/${type}` + search
  );

  data = data.reverse();

  return data;
};

export const updateInvoiceSettings = async (invoiceData) => {
  const { data } = await toolsClient.post(
    `/accounts/invoiceSettings`,
    invoiceData
  );

  return data;
};

export const getInvoiceSettings = async (id) => {
  const { data } = await toolsClient.get(`/accounts/invoiceSettings/${id}`);

  return data;
};
