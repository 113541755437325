import { createCustomer, getClickId } from "@/api/tapfiliate.api";
import Keycloak from "keycloak-js";
import store from "./store/store";

const keycloak = new Keycloak({
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
});

keycloak.onReady = async (authenticated) => {
  if (!authenticated) {
    keycloak.logout();
  }

  try {
    const user = await store.dispatch("Profile/fetchUser", {}, { root: true });

    const referralCode = localStorage.getItem("referral_code");

    if (referralCode) {
      const clickId = await getClickId(referralCode);

      if (clickId) {
        const metaData = {
          id: user.id,
          email: user.email,
          first_name: user.basic_info.first_name,
          last_name: user.basic_info.last_name,
        };

        await createCustomer(clickId, user.email, metaData);

        localStorage.removeItem("referral_code");
      }
    }

    require("./bootstrap");
  } catch (e) {
    if (e.response.status === 401) {
      keycloak.logout();
    }
  }
};

keycloak.onAuthSuccess = () => {
  window.auth = keycloak;
};

keycloak.onAuthError = () => {
  keycloak.logout();
};

keycloak.onAuthRefreshSuccess = () => {
  window.auth = keycloak;
};

keycloak.onAuthRefreshError = () => {
  keycloak.logout();
};

keycloak.onAuthLogout = () => console.log("onAuthLogout!");

keycloak.onTokenExpired = async () => {
  const refreshed = await keycloak.updateToken(60);

  if (!refreshed) {
    keycloak.logout();
  }
};

keycloak.init({
  onLoad: "login-required",
  checkLoginIframe: false,
});
