import { getAutomationSettings, makeAutomationRequest } from "@/api/stationAutomation.api"

export const fetchAutomationSettings = async ({ commit }, stationKey) => {
  const settings = await getAutomationSettings(stationKey)

  commit("setAutomationSettings", settings)
}

export const requestAutomation = async ({ commit }, { stationKey, request }) => {
  const settings = await makeAutomationRequest(stationKey, request)

  commit("setAutomationSettings", settings)
}
