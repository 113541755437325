import toolsClient from "@/api/toolsClient";

const list = async (type = null, id = null) => {
  const url = type && id ? `${type}/${id}` : `portal`;

  const { data } = await toolsClient.get(`${url}/people`);

  return data;
};

export const removeRole = async (user, role, type = null, id = null) => {
  let userId = null;

  if (user.id) {
    userId = user.id;
  } else if (user.email) {
    userId = `?email=${encodeURIComponent(user.email)}`;
  }

  if (!userId) {
    throw new Error("User id or email is required");
  }

  const url = type && id ? `${type}/${id}` : `portal`;

  await toolsClient.delete(`${url}/people/${role}/${userId}`);
};

export const sendInvite = async (
  email,
  role,
  type = null,
  identifier = null
) => {
  const url = type && identifier ? `${type}/${identifier}` : `portal`;

  await toolsClient.post(`${url}/people/${role}?email=${encodeURIComponent(email)}`);
};

export const getInvited = async (type, identifier) => {
  const { data } = await toolsClient.get(`${type}/${identifier}/invites`);

  return data;
};

export const cancelInvite = async (token, type, identifier) => {
  await toolsClient.delete(`${type}/${identifier}/invites/${token}`);
};

export default {
  list,
  removeRole,
  getInvited,
  sendInvite,
  cancelInvite,
};
