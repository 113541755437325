export const uploadQueue = (state) => {
  return state.uploadQueue
}

export const isUploading = (state) => {
  if (state.uploadQueue.length === 0) {
    return false
  }

  return state.uploadQueue.some((media) => ["pending", "uploading"].includes(media.status))
}

export const pendingUploads = (state) => {
  return state.uploadQueue.filter((media) => media.status === "pending")
}
