import moment from "moment";

export const hasFinanceData = (state) => {
  return state.hasFinanceData;
};

export const financeData = (state) => {
  return state.financeData;
};

export const invoiceSettings = (state) => {
  return state.invoiceSettings;
};

export const adRevenue = (state) => {
  return state.financeData.map((a) => a.audioAdRevenue + a.displayAdRevenue);
};

export const adRevenueTotal = (state, getters) => {
  return getters.audioAdRevenueTotal + getters.displayAdRevenueTotal;
};

export const audioAdImpressions = (state) => {
  return state.financeData.map((a) => a.audioAdImpressions);
};

export const audioAdImpressionsTotal = (state, getters) => {
  return getters.audioAdImpressions.reduce((a, b) => a + b, 0);
};

export const audioAdRevenue = (state) => {
  return state.financeData.map((a) => a.audioAdRevenue);
};

export const audioAdRevenueTotal = (state, getters) => {
  return getters.audioAdRevenue.reduce((a, b) => a + b, 0);
};

export const displayAdImpressions = (state) => {
  return state.financeData.map((a) => a.displayAdImpressions);
};

export const displayAdImpressionsTotal = (state, getters) => {
  return getters.displayAdImpressions.reduce((a, b) => a + b, 0);
};

export const displayAdRevenue = (state) => {
  return state.financeData.map((a) => a.displayAdRevenue);
};

export const displayAdRevenueTotal = (state, getters) => {
  return getters.displayAdRevenue.reduce((a, b) => a + b, 0);
};

export const timePeriods = (state, getters) => {
  const start = new Date(getters[getters.activeTimeFrame].from);
  const end = new Date(getters[getters.activeTimeFrame].to);

  let arr = [];

  if (getters.isDaily) {
    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt).toISOString().slice(0, 10));
    }
  } else {
    const a = moment(start);
    const b = moment(end);

    const diffInMonths = Math.abs(a.diff(b, "months"));

    for (let i = 0; i <= diffInMonths; i++) {
      arr.push(moment(start).add(i, "months").format("MMM, YYYY"));
    }
  }

  return arr;
};

export const timePeriod = (state, getters) => {
  const start = new Date(getters[getters.activeTimeFrame].from);
  const end = new Date(getters[getters.activeTimeFrame].to);

  let arr = [];

  if (getters.isDaily) {
    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt).toISOString().slice(0, 10));
    }
  } else {
    const a = moment(start);
    const b = moment(end);

    const diffInMonths = Math.abs(a.diff(b, "months"));

    for (let i = 0; i <= diffInMonths; i++) {
      arr.push(moment(start).add(i, "months").format("MMM, YYYY"));
    }
  }

  return arr;
};

/**
 * Time Frame
 */
export const isCustomTimeFrame = (state) => {
  return state.timeFrames.find(
    (timeFrame) => timeFrame.value === "custom" && timeFrame.selected
  );
};

export const timeFrames = (state) => {
  return state.timeFrames;
};

export const activeTimeFrame = (state) => {
  return state.timeFrames.find((frame) => frame.selected).value;
};

export const today = () => {
  return {
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  };
};

export const yesterday = () => {
  return {
    from: moment().subtract(1, "day").format("YYYY-MM-DD"),
    to: moment().subtract(1, "day").format("YYYY-MM-DD"),
  };
};

export const thisWeek = () => {
  return {
    from: moment().startOf("week").format("YYYY-MM-DD"), //first day of this week. Week starts on Sunday
    to: moment().endOf("week").format("YYYY-MM-DD"),
  };
};

export const lastWeek = () => {
  return {
    from: moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD"), //first day of previous week. Week starts on Sunday
    to: moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD"), //last day of previous week. Week ends on Saturday
  };
};

export const thisMonth = () => {
  return {
    from: moment().startOf("month").format("YYYY-MM-DD"), //first day of this month.
    to: moment().endOf("month").format("YYYY-MM-DD"),
  };
};

export const lastMonth = () => {
  return {
    from: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD"), //first day of previous month
    to: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"), //last day of previous month
  };
};

export const custom = (state) => {
  return {
    from: state.customTimeFrame.from
      ? state.customTimeFrame.from
      : moment().format("YYYY-MM-DD"),
    to: state.customTimeFrame.to
      ? state.customTimeFrame.to
      : moment().format("YYYY-MM-DD"),
  };
};

/**
 * Data Frequency
 */
export const dataFrequencies = (state) => {
  return state.dataFrequencies;
};

export const activeFrequency = (state) => {
  return state.dataFrequencies.find((frequency) => frequency.selected).value;
};

export const isDaily = (state) => {
  return (
    state.dataFrequencies.find((frequency) => frequency.selected).value ===
    "daily"
  );
};

/**
 * Datatype
 */
export const activeDatatype = (state) => {
  return state.dataTypes.find((type) => type.selected).value;
};

export const isTotal = (state, getters) => {
  return getters.activeDatatype === "total";
};

export const isAudio = (state, getters) => {
  return getters.activeDatatype === "audio";
};

export const isDisplay = (state, getters) => {
  return getters.activeDatatype === "display";
};
