import axios from "axios"

const toolsClient = axios.create({
  baseURL: process.env.VUE_APP_TOOLS_SERVICE_URL + "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
})

toolsClient.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + window.auth.token

    return config
  },
  (error) => {
    console.log("[TOOLS] Request error", error.message, error.response)
    return Promise.reject(error)
  }
)

toolsClient.interceptors.response.use(null, (e) => {
  if (e.response) {
    console.log("The request was made and the server responded with an error.")
    console.log("[TOOLS]", e.message, e.response)
  } else if (e.request) {
    console.log("The request was made but no response was received.")
    console.log("[TOOLS]", e.message, e.request)
  } else {
    console.log("Something happened in setting up the request that triggered an error.")
    console.log("[TOOLS]", e.message)
  }

  return Promise.reject(e)
})

export default toolsClient
