export const playerPlay = async ({ commit }) => {
  commit("setPlayingStatus", true);
};

export const playerPause = async ({ commit }) => {
  commit("setPlayingStatus", false);
};

export const changePlayerStation = async ({ commit }, playerStation) => {
  commit("setPlayerStation", playerStation);
};

export const resetPlayer = async ({ commit }) => {
  commit("reset");
};
