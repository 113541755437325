import stationApi from "@/api/station.api"
import stationServerApi from "@/api/stationServer.api"
import stationAudioAdsApi from "@/api/stationAudioAds.api"

export const searchStations = async ({ query, limit, offset }) => {
  return await stationApi.search(query, limit, offset)
}

export const getStationList = async ({ pageNumber, pageSize, sortBy, sortDesc, search }) => {
  return await stationApi.list(pageNumber, pageSize, sortBy, sortDesc, search)
}

export const getStation = async (id) => {
  return await stationApi.get(id)
}

export const createStation = async (accountId, stationData) => {
  return await stationApi.create(accountId, stationData)
}

export const updateStation = async (data) => {
  return await stationApi.update(data)
}

export const suspendStation = async (id) => {
  return await stationApi.suspend(id)
}

export const reactivateStation = async (id) => {
  return await stationApi.reactivate(id)
}

export const deleteStation = async (id) => {
  return await stationApi.destroy(id)
}

export const getStationProfile = async (id) => {
  return await stationApi.getProfile(id)
}

export const updateStationProfile = async (id, data) => {
  return await stationApi.updateProfile(id, data)
}

export const getStationMasterLink = async (id) => {
  return await stationApi.getMasterLink(id)
}

export const updateStationMasterLink = async (id, data) => {
  return await stationApi.updateMasterLink(id, data)
}

export const resetStationMasterLink = async (id) => {
  return await stationApi.resetMasterLink(id)
}

export const updateStationSlug = async (id, slug) => {
  await stationApi.updateSlug(id, slug)
}

export const changeStationAccount = async (id, accountId) => {
  await stationApi.changeAccount(id, accountId)
}

export const restartStationServer = async (id) => {
  await stationServerApi.restart(id)
}

export const startStationServer = async (id) => {
  await stationServerApi.start(id)
}

export const getStationAudioAdsStatus = async (id) => {
  return await stationAudioAdsApi.getStatus(id)
}

export const updateStationAudioAdsStatus = async (id, status, applyToRelays = null) => {
  return await stationAudioAdsApi.updateStatus(id, status, applyToRelays)
}

export const createStationAudioAdsAgreement = async (id) => {
  return await stationAudioAdsApi.createAgreement(id)
}

export const getStationAudioAdsAgreement = async (id, include_doc) => {
  return await stationAudioAdsApi.getAgreement(id, include_doc)
}

export default {
  searchStations,
  getStationList,
  getStation,
  createStation,
  updateStation,
  suspendStation,
  reactivateStation,
  deleteStation,
  getStationProfile,
  updateStationProfile,
  getStationMasterLink,
  updateStationMasterLink,
  resetStationMasterLink,
  updateStationSlug,
  changeStationAccount,
  restartStationServer,
  startStationServer,
  getStationAudioAdsStatus,
  updateStationAudioAdsStatus,
  createStationAudioAdsAgreement,
  getStationAudioAdsAgreement,
}
