import UserService from "@/services/UserService";

export const fetchUser = async (vuex) => {
  const user = await UserService.getUser();

  localStorage.setItem("userInfo", JSON.stringify(user));

  vuex.commit("setUser", user);

  return user;
};

export const updateUser = async (vuex, data) => {
  const user = await UserService.updateUser(data);

  await UserService.updateUserProfile({
    firstName: data.basic_info.first_name,
    lastName: data.basic_info.last_name,
  });

  localStorage.setItem("userInfo", JSON.stringify(user));

  vuex.commit("setUser", user);

  return user;
};
