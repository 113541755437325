import { config } from "/app.config";

export default config.isZeno
  ? [
      {
        path: "advertising",
        name: "account.advertising",
        component: () => import("@/views/Advertising/Advertising"),
        meta: {
          pageTitle: "Advertising",
          rule: "owner",
        },
      },
    ]
  : [];
