import {initialState} from "./state"

export const setSchedule = (state, payload) => {
  state.schedule = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach(key => {
    state[key] = s[key]
  })
}
