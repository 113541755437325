function initialState() {
  return {
    changelog: [],
  }
}

let state = initialState()

export {
  initialState,
  state
}
