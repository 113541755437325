import VueI18n from "vue-i18n"
import Vue from "vue"
import {config} from "../../app.config";
import en_default from "@/locales/en.json"
import en_aiir from "@/locales/Aiir/en.json"

Vue.use(VueI18n)

const en = config.appName === "Aiir"
  ? en_aiir
  : en_default

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {en},
})
