import * as api from "@/api/podcasts.api";

export const resetPodcasts = async ({ commit }) => {
  try {
    commit("setPodcasts", []);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const resetPodcast = async ({ commit }) => {
  try {
    commit("resetPodcast");
    commit("Episode/setEpisodes", [], { root: true });
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getLibrary = async ({ commit }, accountKey) => {
  try {
    let library = await api.getLibrary(accountKey);

    commit("setLibrary", library);

    return library;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getPodcasts = async ({ commit }) => {
  try {
    let podcasts = await api.getPodcasts();

    commit("setPodcasts", podcasts);

    return podcasts;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getPodcast = async ({ commit, dispatch }, payload) => {
  try {
    let podcast;

    if (payload.accountKey) {
      await dispatch("getLibrary", payload.accountKey);

      podcast = await api.getPodcast(payload.podcastKey);
    } else {
      podcast = await api.getPodcast(payload);
    }

    commit("setPodcast", podcast);

    return podcast;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const storePodcast = async ({ commit }, data) => {
  try {
    let podcast = await api.storePodcast(data);

    commit("setPodcast", podcast);

    return podcast;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updatePodcast = async ({ commit }, data) => {
  try {
    let podcast = await api.updatePodcast(data);

    commit("setPodcast", podcast);

    return podcast;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deletePodcast = async ({ dispatch }, podcastKey) => {
  try {
    await api.deletePodcast(podcastKey);

    await dispatch("resetPodcast");

    await dispatch("getPodcasts");
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const uploadImage = async (vuex, file) => {
  try {
    return await api.uploadImage(file);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const importPodcast = async (vuex, url) => {
  try {
    return await api.importPodcast(url);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getTaskStatus = async (vuex, task) => {
  try {
    return await api.getTaskStatus(task);
  } catch (e) {
    console.log(e);
    throw e;
  }
};
