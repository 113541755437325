export const podcasts = (state) => {
  return state.podcasts;
};

export const podcast = (state) => {
  return state.podcast;
};

export const library = (state) => {
  return state.library;
};

export const podcastCategories = (state) => {
  return state.categories;
};
