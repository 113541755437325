<template>
  <span v-show="message || !!errors.length" class="text-warning text-sm">
    {{ message ? message : errors[0] }}
  </span>
</template>

<script>
export default {
  name: "f-error",

  props: {
    errors: {
      type: Array,
    },
    message: {
      type: String,
    },
  },
};
</script>
