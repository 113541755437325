import toolsClient from "@/api/toolsClient";

const ENDPOINT = "/tools/changelog/";

export const getAll = async () => {
  let response = await toolsClient.get(ENDPOINT);

  return response.data;
};

export const create = async (data) => {
  let response = await toolsClient.post(ENDPOINT, data);

  return response.data;
};

export const update = async (notification) => {
  let response = await toolsClient.put(ENDPOINT, notification);

  return response.data;
};

export const get = async (id) => {
  let response = await toolsClient.get(ENDPOINT + id);

  return response.data;
};

export const remove = async (id) => {
  let response = await toolsClient.delete(ENDPOINT + id);

  return response.data;
};
