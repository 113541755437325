<template>
  <vs-button
    v-clipboard:copy="text"
    v-clipboard:success="copied"
    color="primary"
    icon="icon-copy"
    icon-pack="feather"
  >
  </vs-button>
</template>

<script>
export default {
  name: "btn-copy",

  props: {
    text: {
      type: String,
      default: "source",
    },
    copy: {
      type: Function,
      default: () => this.copied,
    },
  },

  methods: {
    copied() {
      this.notifSuccess("Copied!");
    },
  },
};
</script>

<style scoped></style>
