import {
  SUBSCRIPTION_ACCOUNT_PREMIER,
  SUBSCRIPTION_ACCOUNT_CUSTOM_STATIONS,
  SUBSCRIPTION_ACCOUNT_PRIME,
  SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE,
  SUBSCRIPTION_ACCOUNT_STARTER,
} from "./state";
import { config as appConfig, config } from "/app.config";

export const accountSubscriptions = (state) => {
  return state.accountSubscriptions;
};

export const hasAccountSubscriptions = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  const { isPrime, isStarter, isAdsFree, isPremier, isCustomStations } = getters;
  const isPortalAdmin = rootGetters["Profile/isPortalAdmin"];
  const { isZeno, appName } = config;

  if (isPrime || isStarter || isAdsFree || isPremier || isCustomStations) {
    return isPrime || isStarter || isAdsFree || isPremier || isCustomStations;
  }

  if (isPortalAdmin || (!isZeno && appName !== "Aiir")) {
    return true;
  }

  return state.accountSubscriptions && state.accountSubscriptions.length > 0;
};

export const hasAccountActiveSubscriptions = (state) => {
  const activeSubscriptions = state.accountSubscriptions?.filter(
    (subscription) => subscription.active != "active"
  );
  return activeSubscriptions?.length > 0;
};

export const accountActiveSubscriptionsPeriod = (state) => {
  const activeSubscriptions = state.accountSubscriptions?.filter(
    (subscription) => subscription.active != "active"
  );

  if (activeSubscriptions?.length) {
    const activeItem = activeSubscriptions[0]?.items?.find((item) => {
      return (
        item.price?.active &&
        (item.price.product.id === SUBSCRIPTION_ACCOUNT_STARTER ||
          item.price.product.id === SUBSCRIPTION_ACCOUNT_PRIME ||
          item.price.product.id === SUBSCRIPTION_ACCOUNT_PREMIER ||
          item.price.product.id === SUBSCRIPTION_ACCOUNT_CUSTOM_STATIONS ||
          item.price.product.id === SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE)
      );
    });

    return activeItem?.price?.recurring?.interval;
  }
};

export const getSubscription = (state) => (priceId) => {
  if (!state.accountSubscriptions || !state.accountSubscriptions.length) {
    return false;
  }

  return state.accountSubscriptions.find((subscription) => {
    return subscription.items.find((item) => {
      return item.price.id === priceId;
    });
  });
};

export const hasSubscriptionToProduct = (state) => (productId) => {
  if (!productId || !state.accountSubscriptions || !state.accountSubscriptions.length) {
    return false;
  }

  return state.accountSubscriptions.find((subscription) =>
    subscription.items.find((item) => item.price.product.id === productId)
  );
};

export const isPrime = (state, getters) => {
  return getters.hasSubscriptionToProduct(SUBSCRIPTION_ACCOUNT_PRIME);
};

export const isStarter = (state, getters) => {
  return getters.hasSubscriptionToProduct(SUBSCRIPTION_ACCOUNT_STARTER);
};

export const isAdsFree = (state, getters) => {
  return getters.hasSubscriptionToProduct(SUBSCRIPTION_ACCOUNT_PRIME_ADS_FREE);
};

export const isPremier = (state, getters) => {
  return getters.hasSubscriptionToProduct(SUBSCRIPTION_ACCOUNT_PREMIER);
};

export const isCustomStations = (state, getters) => {
  return getters.hasSubscriptionToProduct(SUBSCRIPTION_ACCOUNT_CUSTOM_STATIONS);
};

export const plans = (state) => {
  return state.plans;
};

export const accountPlans = (state) => {
  return state.accountPlans;
};

export const currentPlanName = (state, getters) => {
  let planName = null;

  if (getters.isStarter) {
    if (appConfig.appName === "Aiir") {
      planName = "Streaming 1";
    } else {
      planName = "Starter";
    }
  } else if (getters.isPrime || getters.isAdsFree) {
    if (appConfig.appName === "Aiir") {
      planName = "Streaming 2";
    } else {
      planName = "Prime";
    }
  } else if (getters.isPremier) {
    if (appConfig.appName === "Aiir") {
      planName = "Streaming 3";
    } else {
      planName = "Premier";
    }
  } else if (getters.isCustomStations) {
    if (appConfig.appName === "Aiir") {
      planName = "Streaming";
    } else {
      planName = "Custom Plan";
    }
  }

  return planName;
};
