import { libraryClient } from "@/api/podcasts.api";

export const getPodcastRecordingScheduleList = async (podcastKey) => {
  const response = await libraryClient.get(
    `api/podcasts/${podcastKey}/recordingScheduleTable`
  );

  return response.data;
};

export const createPodcastRecordingSchedule = async (data) => {
  const response = await libraryClient.post(
    `/api/podcasts/${data.podcastKey}/recordingSchedule`,
    data
  );

  return response.data;
};

export const updatePodcastRecordingSchedule = async (data) => {
  const response = await libraryClient.put(
    `/api/podcasts/${data.podcastKey}/recordingSchedule/${data.id}/`,
    data
  );

  return response.data;
};

export const deletePodcastRecordingSchedule = async (podcastKey, id) => {
  const response = await libraryClient.delete(
    `/api/podcasts/${podcastKey}/recordingSchedule/${id}/`
  );

  return response.data;
};
