import toolsClient from "@/api/toolsClient";

export const API_END_POINT = "stations/";

/**
 * ================================
 * STATION PLAYER
 * ================================
 */
export const getPlayer = async (stationKey) => {
  const response = await toolsClient.get(
    API_END_POINT + `${stationKey}/player`
  );

  return response.data;
};

export const updatePlayer = async (stationKey, data) => {
  const response = await toolsClient.put(
    API_END_POINT + `${stationKey}/player`,
    data
  );

  return response.data;
};

/**
 * ================================
 * OTHER
 * ================================
 */
export const fetchToolsDIDs = async (stationKey) => {
  const response = await toolsClient.get(
    API_END_POINT + `${stationKey}/ctl/toolsdids`
  );

  return response.data;
};

export const getFeatured = async (stationKey) => {
  const response = await toolsClient.get(`stations/${stationKey}/featured`);

  return response.data;
};

export const toggleFeatured = async (stationKey, toggle = true) => {
  const response = await toolsClient.put(
    `stations/${stationKey}/featured/${toggle}`
  );

  return response.data;
};
