function initialState() {
  return {
    uploadQueue: [],
    uploadAbortController: null,
  }
}

let state = initialState()

export { initialState, state }
