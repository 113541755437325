export const stationAutomationSettings = (state) => {
  return state.automationSettings;
};

export const stationAutomationSelected = (state) => {
  return (
    typeof state.automationSettings.status !== "undefined" &&
    state.automationSettings.status !== null
  );
};

export const autoDJEnabled = (state) => {
  return state.automationSettings.hasAutoDj;
};

export const libreTimeEnabled = (state) => {
  return state.automationSettings.hasLibretime;
};

export const libreTimePending = (state) => {
  return state.automationSettings.status === "request_pending";
};

export const libreTimeActive = (state) => {
  return (
    state.automationSettings.hasLibretime === true &&
    state.automationSettings.status === "enabled"
  );
};
