import toolsClient from "@/api/toolsClient"

export const getAutoDj = async (stationKey) => {
  try {
    const response = await toolsClient.get(`stations/${stationKey}/auto_dj/`)

    return response.data
  } catch (e) {
    console.error("Error fetching AutoDJ data for station:", stationKey, e)
    throw e
  }
}

export const updatePlaylistShuffle = async (stationKey, shuffle) => {
  try {
    await toolsClient.put(`stations/${stationKey}/auto_dj/shuffle/${shuffle}`)
  } catch (e) {
    console.error("Error shuffling playlist tracks for station:", stationKey, shuffle, e)
    throw e
  }
}

export const updatePlaylistTracksOrder = async (stationKey, references) => {
  try {
    await toolsClient.put(`stations/${stationKey}/auto_dj/reorderTracks`, references)
  } catch (e) {
    console.error("Error reordering playlist tracks for station:", stationKey, references, e)
    throw e
  }
}

export const addPlaylistTrack = async (stationKey, reference) => {
  try {
    const response = await toolsClient.post(`stations/${stationKey}/auto_dj/track/${reference}`)

    return response.data
  } catch (e) {
    console.error("Error adding playlist track for station:", stationKey, reference, e)
    throw e
  }
}

export const restartPlaylist = async (stationKey) => {
  try {
    await toolsClient.post(`stations/${stationKey}/auto_dj/reset/`)
  } catch (e) {
    console.error("Error restarting AutoDJ playlist for station:", stationKey, e)
    throw e
  }
}

export const getCurrentlyPlaying = async (stationKey) => {
  try {
    const response = await toolsClient.get(`stations/${stationKey}/auto_dj/current?_=` + Date.now())

    return response.data
  } catch (e) {
    console.error("Error fetching currently playing track for station:", stationKey, e)
    throw e
  }
}

export const deletePlaylistTracks = async (stationKey, references) => {
  try {
    await toolsClient.delete(`stations/${stationKey}/auto_dj/tracks`, {
      data: references,
    })
  } catch (e) {
    console.error("Error deleting playlist tracks for station:", stationKey, references, e)
    throw e
  }
}
