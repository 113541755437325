export const autoDj = (state) => {
  return state.autoDj
}

export const previewURL = (state) => {
  return state.previewURL
}

export const autoDjPlaylist = (state) => {
  return state.autoDj.playlist
}

export const loadingAutoDj = (state) => {
  return state.loadingAutoDj
}

export const tracksCount = (state) => {
  return state.autoDj.playlist.length
}

export const allowedToUploadCount = (state, getters, rootState, rootGetters) => {
  const tracksLimit = rootGetters['Subscription/allowedTracksCount']

  if (tracksLimit === null || getters.tracksCount >= tracksLimit) {
    return 0
  }

  return tracksLimit - getters.tracksCount
}

export const current = (state) => {
  return state.current
}
