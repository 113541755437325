import toolsClient from "@/api/toolsClient"

const getStatus = async (id) => {
  const { data } = await toolsClient.get(`stations/${id}/audioads`)

  return data
}

const updateStatus = async (id, status, applyToRelays = null) => {
  applyToRelays = applyToRelays !== null ? "?applyToRelays=" + !!applyToRelays : ""

  const { data } = await toolsClient.put(`stations/${id}/audioads/${status}` + applyToRelays)

  return data
}

const getAgreement = async (id, include_doc = false) => {
  const { data } = await toolsClient.get(`stations/${id}/agreement/audioads?include_doc=${include_doc}`)
  return data
}

const createAgreement = async (id) => {
  const { data } = await toolsClient.post(`stations/${id}/agreement/audioads`)
  return data
}

export default {
  getStatus,
  updateStatus,
  getAgreement,
  createAgreement,
}
