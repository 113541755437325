import toolsClient from "@/api/toolsClient";

export const getRelays = async (stationKey) => {
  const response = await toolsClient.get(`stations/${stationKey}/relays/`);

  return response.data;
};

export const storeRelay = async (stationKey, data) => {
  const response = await toolsClient.post(
    `stations/${stationKey}/relays/`,
    data
  );

  return response.data;
};

export const changeMaster = async (stationKey, relayKey) => {
  const response = await toolsClient.post(
    `stations/${stationKey}/relays/${relayKey}`
  );

  return response.data;
};
