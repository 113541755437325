export const STATION_TYPE_MASTER = 1;
export const STATION_TYPE_RELAY = 2;

function initialState() {
  return {
    STATION_TYPE_MASTER: STATION_TYPE_MASTER,
    STATION_TYPE_RELAY: STATION_TYPE_RELAY,
    accountStations: null,
    accountDeletedStations: null,
    undeleteStation: {},
  };
}

let state = initialState();

export { initialState, state };
