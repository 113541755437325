import router from "@/router";

export const accounts = (state) => {
  return state.accounts && state.accounts.length > 0 ? state.accounts : [];
};

export const account = (state) => {
  return state.account;
};

export const accountKey = (state) => {
  if (state.account.key) {
    return state.account.key;
  } else if (router.currentRoute.params.accountKey) {
    return router.currentRoute.params.accountKey;
  }

  return "";
};

export const logo = (state) => {
  if (state.account.key) {
    return (
      process.env.VUE_APP_TOOLS_SERVICE_URL +
      `/content/accounts/${state.account.key}/image/?updated=${Date.now()}`
    );
  }

  return "";
};
