export const SUBSCRIPTION_STATION_SPONSORED_MONTH =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_STATION_SPONSORED_MONTH;
export const SUBSCRIPTION_STATION_MUSIC_MONTH =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_STATION_MUSIC_MONTH;
export const SUBSCRIPTION_STATION_CTL =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_STATION_CTL;
export const SUBSCRIPTION_STATION_CTL_MONTH =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_STATION_CTL_MONTH;
export const SUBSCRIPTION_STATION_CTL_YEAR =
  process.env.VUE_APP_STRIPE_SUBSCRIPTION_STATION_CTL_YEAR;

function initialState() {
  return {
    subscriptionList: null,
    sponsoredSubscription: null,

    subscriptions: {
      sponsored: {
        title: "Sponsored",
        pricing: [
          {
            id: SUBSCRIPTION_STATION_SPONSORED_MONTH,
            period: "month",
            price: "$74.90",
          },
        ],
      },
      music: {
        title: "Music",
        pricing: [
          {
            id: SUBSCRIPTION_STATION_MUSIC_MONTH,
            period: "month",
            price: "$9.99",
          },
        ],
      },
      ctl: {
        title: "Call-To-Listen",
        pricing: [
          {
            id: SUBSCRIPTION_STATION_CTL_MONTH,
            period: "month",
            price: "$5.99",
          },
          {
            id: SUBSCRIPTION_STATION_CTL_YEAR,
            period: "year",
            price: "$65.00",
          },
        ],
      },
    },

    plans: {
      ctl: [
        {
          period: "month",
          id: SUBSCRIPTION_STATION_CTL_MONTH,
          price: "$5.99",
        },
        {
          period: "year",
          id: SUBSCRIPTION_STATION_CTL_YEAR,
          price: "$65.00",
        },
      ],
      music: [
        {
          period: "month",
          id: SUBSCRIPTION_STATION_MUSIC_MONTH,
          price: "$9.99",
        },
      ],
      sponsored: [
        {
          period: "month",
          id: SUBSCRIPTION_STATION_SPONSORED_MONTH,
          price: "$74.90",
        },
      ],
    },
  };
}

let state = initialState();

export { initialState, state };
