import portalPartners from "@/routes/portal/portalPartners";
import portalSettings from "@/routes/portal/portalSettings";

export default [
  {
    path: "/portal",
    component: () => import("@/layouts/main/Portal"),
    meta: {
      pageTitle: "Portal",
      rule: "portalAdmin",
    },
    children: [
      {
        path: "/",
        name: "portal.dashboard",
        component: () => import("@/views/Portal/Dashboard"),
        meta: {
          pageTitle: "Dashboard",
          rule: "portalAdmin",
        },
      },
      {
        path: "accounts",
        name: "portal.accounts",
        component: () => import("@/views/Portal/AccountList"),
        meta: {
          pageTitle: "Accounts",
          rule: "portalAdmin",
        },
      },
      {
        path: "stations",
        name: "portal.stations",
        component: () => import("@/views/Portal/StationList"),
        meta: {
          pageTitle: "Stations",
          rule: "portalAdmin",
        },
      },
      {
        path: "people",
        name: "portal.people",
        component: () => import("@/views/Portal/PortalPeople"),
        meta: {
          pageTitle: "People",
          rule: "portalAdmin",
        },
      },
      ...portalSettings,
      ...portalPartners,
    ],
  },
];
