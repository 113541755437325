function initialState() {
  return {
    numbers: [],
    welcome: ""
  }
}

let state = initialState()

export {
  initialState,
  state
}
