import { stationTransformer } from "@/helpers";
import { getAccountStations, getAccountDeletedStations, getAccountUndeleteStation } from "@/services/AccountService";

export const fetchAccountStations = async (vuex, accountKey) => {
  const stations = await getAccountStations(accountKey);

  vuex.commit("setAccountStations", stations.map(stationTransformer()));
};

export const resetAccountStations = async ({ commit }) => {
  commit("resetStations");
};

// deleted stations
export const fetchAccountDeletedStations = async (vuex, accountKey) => {
  const stations = await getAccountDeletedStations(accountKey);

  vuex.commit("setAccountDeletedStations", stations.map(stationTransformer()));
};

export const resetAccountDeletedStations = async ({ commit }) => {
  commit("resetDeletedStations");
};


export const fetchUndeleteStation = async ({ commit }, data) => {
  const undeleteStation = await getAccountUndeleteStation(data.accountKey,data.stationKey);
  console.log("undeleteStation: ",undeleteStation)
  commit("setUndeleteStation", undeleteStation);

  return undeleteStation;
};