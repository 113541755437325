<template>
  <vs-button
    ref="loadableButton"
    :class="`vs-con-loading__container ${className}`"
    :color="color"
    :disabled="disabled || loadingState"
    :size="size"
    :type="type"
    v-on="listeners"
  >
    <slot />
  </vs-button>
</template>
<script>
export default {
  name: "LoaderButton",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    type: {
      type: String,
      default: "filled",
    },
    size: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "sm:w-auto w-full",
    },
  },

  data: () => ({
    isLoading: false,
  }),

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        click: this.handleClick,
      };
    },
    loadingState() {
      return this.isLoading;
    },
  },

  methods: {
    async handleClick(e) {
      try {
        this.$vs.loading({
          color: this.color,
          container: this.$refs.loadableButton.$el,
          scale: 0.45,
        });

        this.isLoading = true;
        await this.$listeners.click(e);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;

        if (typeof this.$refs.loadableButton !== "undefined") {
          this.$vs.loading.close(this.$refs.loadableButton.$el);
        }
      }
    },
  },
};
</script>
<style></style>
