import {initialState} from "./state"

export const setRelays = (state, payload) => {
  state.relays = payload
}

export const setRelay = (state, payload) => {
  state.relay = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach(key => {
    state[key] = s[key]
  })
}
