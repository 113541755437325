import toolsClient from "@/api/toolsClient";

export const getAll = async () => {
  const response = await toolsClient.get("/tools/notifications");

  return response.data;
};

export const create = async (notification) => {
  const response = await toolsClient.post("/tools/notifications", notification);

  return response.data;
};

export const update = async (notification) => {
  const response = await toolsClient.put("/tools/notifications", notification);

  return response.data;
};

export const get = async (id) => {
  const response = await toolsClient.get("/tools/notifications/" + id);

  return response.data;
};

export const remove = async (id) => {
  const response = await toolsClient.delete("/tools/notifications/" + id);

  return response.data;
};
