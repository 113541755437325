export const subscriptions = (state) => {
  return state.subscriptions;
};

export const hasSubscriptionToProduct = (state) => (productId) => {
  if (state.subscriptionList === null) {
    return null;
  }

  return state.subscriptionList.find((subscription) =>
    subscription.items.find((item) => item.price.product.id === productId)
  );
};

export const subscriptionList = (state) => {
  return state.subscriptionList;
};
