export const accountStations = (state) => {
  return state.accountStations;
};

export const getStationsByType = (state) => (type) => {
  if (!state.accountStations) {
    return null;
  }

  return state.accountStations.filter((station) => station.type === type);
};

export const masters = (state, getters) => {
  return getters.getStationsByType(state.STATION_TYPE_MASTER);
};

export const relays = (state, getters) => {
  return getters.getStationsByType(state.STATION_TYPE_RELAY);
};

export const hasStations = (state, getters) => {
  if (!state.accountStations) {
    return false;
  }

  return !!getters.getStationsByType(state.STATION_TYPE_MASTER).length;
};

export const stationSelectList =
  (state, getters) =>
  (type = 1) => {
    if (!state.accountStations) {
      return [];
    }

    type = parseInt(type);
    let stations = state.accountStations;

    if (type) {
      stations = getters.getStationsByType(type);
    }

    return stations.map((station) => {
      return {
        label: station.name,
        value: station.key,
      };
    });
  };

export const stationMastersSelectList = (state, getters) => {
  return getters.stationSelectList(state.STATION_TYPE_MASTER);
};

// deleted stations
export const accountDeletedStations = (state) => {
  return state.accountDeletedStations;
};

export const getDeletedStationsByType = (state) => (type) => {
  if (!state.accountDeletedStations) {
    return null;
  }

  return state.accountDeletedStations.filter((station) => station.type === type);
};


// undelete station
export const getUndeleteStation = (state) => {
  console.log("---getUndeleteStation: ",state.undeleteStation)

  return state.undeleteStation;
};
