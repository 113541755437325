function initialState() {
  return {
    loadingAutoDj: null,

    autoDj: {
      playlist: [],
      shuffle: false,
      stream: "",
    },

    current: {
      onAir: null,
      stream: "",
      track: "",
    },

    previewURL: null,
  };
}

let state = initialState();

export { initialState, state };
