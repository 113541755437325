import { initialState } from "./state"

export const setAudioAdsSettings = (state, payload) => {
  state.audioAdsSettings = payload
}

export const setAudioAdsAgreement = (state, payload) => {
  state.audioAdsAgreement = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach((key) => {
    state[key] = s[key]
  })
}
