import moment from "moment";

export const royaltyStats = (state) => {
  return state.royaltyStats
}

export const periodStats = (state) => {
  return state.periodStats;
};

export const params = (state) => {
  return state.params;
};

export const deviceStats = (state) => {
  return state.deviceStats;
};

export const regionalStats = (state) => {
  return state.regionalStats;
};

export const isDataFetched = (state) => {
  return (
    state.periodStats !== null ||
    state.deviceStats !== null ||
    state.regionalStats !== null ||
    state.royaltyStats !== null
  );
};

export const analyticPeriods = (state) => {
  return state.analyticPeriods;
};

export const isDayAnalytics = (state) => {
  return state.periodType === "day";
};

export const sessionStats = (state, getters) => {
  if (!getters.isDataFetched) {
    return null;
  }

  let sessionStats = {
    timePeriod: [],
    connections: [],
    uniques: [],
    listeningTime: [],
    listeningTimeMinutes: [],
    listeningTimePC: [],
    // bytes: [],
    connected: [],
    disconnected: [],
    streams: [],
    impressions: [],
    thirdQuartile: [],
  };

  let stats = getters.periodStats;

  if (stats && stats.length) {
    for (let i = 0; i < stats.length; i++) {
      if (getters.isDayAnalytics) {
        sessionStats.timePeriod.push(("0" + stats[i].hour).slice(-2) + ":00");
      } else {
        sessionStats.timePeriod.push(stats[i].day);
      }

      if (stats[i].stats.connections !== undefined) {
        sessionStats.connections.push(stats[i].stats.connections);
      }

      if (stats[i].stats.uniques !== undefined) {
        sessionStats.uniques.push(stats[i].stats.uniques);
      }

      if (stats[i].stats.listening_time !== undefined) {
        sessionStats.listeningTime.push(stats[i].stats.listening_time);
        sessionStats.listeningTimeMinutes.push(
          moment
            .duration(stats[i].stats.listening_time, "seconds")
            .asMinutes()
            .toFixed(2)
        );
      }

      if (stats[i].stats.connected !== undefined) {
        sessionStats.connected.push(stats[i].stats.connected);
      }

      if (stats[i].stats.disconnected !== undefined) {
        sessionStats.disconnected.push(stats[i].stats.disconnected);
      }

      if (stats[i].stats.impressions !== undefined) {
        sessionStats.impressions.push(stats[i].stats.impressions);
      }

      if (stats[i].stats.thirdQuartile !== undefined) {
        sessionStats.thirdQuartile.push(stats[i].stats.thirdQuartile);
      }

      if (stats[i].stats.connections !== undefined) {
        let duration = 0;
        if (stats[i].stats.connections > 0) {
          duration = moment.duration(
            stats[i].stats.listening_time / stats[i].stats.connections,
            "seconds"
          );
          duration = duration.asMinutes().toFixed(2);
        }
        sessionStats.listeningTimePC.push(parseFloat(duration));
      }
    }
  }

  return sessionStats;
};

export const thirdQuartile = (state, getters) => {
  return getters.sessionStats.thirdQuartile.length
    ? getters.sessionStats.thirdQuartile.reduce(getSum)
    : 0;
};

export const totalConnections = (state, getters) => {
  return getters.sessionStats.connections.length
    ? getters.sessionStats.connections.reduce(getSum)
    : 0;
};

export const totalConnected = (state, getters) => {
  return getters.sessionStats.connected.length
    ? getters.sessionStats.connected.reduce(getSum)
    : 0;
};

export const totalDisconnections = (state, getters) => {
  return getters.sessionStats.disconnected.length
    ? getters.sessionStats.disconnected.reduce(getSum)
    : 0;
};

export const totalUniques = (state, getters) => {
  return getters.sessionStats.uniques.length
    ? getters.sessionStats.uniques.reduce(getSum)
    : 0;
};

export const totalImpressions = (state, getters) => {
  return getters.sessionStats.impressions.length
    ? getters.sessionStats.impressions.reduce(getSum)
    : 0;
};

export const totalListeningTime = (state, getters) => {
  return getters.sessionStats.listeningTime.length
    ? getters.sessionStats.listeningTime.reduce(getSum)
    : 0;
};

export const totalListeningTimeHours = (state, getters) => {
  return getters.totalListeningTime
    ? moment
        .duration(getters.totalListeningTime, "seconds")
        .asHours()
        .toFixed(2)
    : 0;
};

export const totalListeningTimeMinutes = (state, getters) => {
  return getters.totalListeningTime
    ? moment
        .duration(getters.totalListeningTime, "seconds")
        .asMinutes()
        .toFixed(2)
    : 0;
};

export const averageListeningTimeMinutes = (state, getters) => {
  return getters.totalListeningTime && getters.totalConnections
    ? moment
        .duration(
          getters.totalListeningTime / getters.totalConnections,
          "seconds"
        )
        .asMinutes()
        .toFixed(2)
    : 0;
};

export const totalCallingTimeMinutes = (state, getters) => {
  return getters.totalListeningTime
    ? moment
        .duration(getters.totalListeningTime, "seconds")
        .asMinutes()
        .toFixed(2)
    : 0;
};

export const averageCallingTimeMinutes = (state, getters) => {
  return getters.totalListeningTime && getters.totalConnected
    ? moment
        .duration(
          getters.totalListeningTime / getters.totalConnected,
          "seconds"
        )
        .asMinutes()
        .toFixed(2)
    : 0;
};

export const averageConnections = (state, getters) => {
  return getters.sessionStats.connections.length
    ? (
        getters.totalConnections / getters.sessionStats.connections.length
      ).toFixed(2)
    : 0;
};

export const averageConnected = (state, getters) => {
  return getters.sessionStats.connected.length
    ? (getters.totalConnected / getters.sessionStats.connected.length).toFixed(
        2
      )
    : 0;
};

export const averageDisconnections = (state, getters) => {
  return getters.sessionStats.disconnected.length
    ? (
        getters.totalDisconnections / getters.sessionStats.disconnected.length
      ).toFixed(2)
    : 0;
};

export const cityStats = (state) => {
  return state.cityStats;
};

export const appStats = (state, getters) => {
  if (!getters.isDataFetched) {
    return null;
  }

  let sessionStats = {
    timePeriod: [],
    connections: [],
    minutes: [],
    usersIos: [],
    usersAndroid: []
  };

  let stats = getters.periodStats;

  if (stats && stats.length) {
    for (let i = 0; i < stats.length; i++) {
      if (getters.isDayAnalytics) {
        sessionStats.timePeriod.push(stats[i].date.slice(11));
      } else {
        sessionStats.timePeriod.push(stats[i].date);
      }

      if (stats[i].sessions !== undefined) {
        sessionStats.connections.push(stats[i].sessions);
      }

      if (stats[i].minutes !== undefined) {
        sessionStats.minutes.push(stats[i].minutes);
      }

      if (stats[i].uniques !== undefined && stats[i].uniques.ios !== undefined) {
        sessionStats.usersIos.push(stats[i].uniques.ios);
      }

      if (stats[i].uniques !== undefined && stats[i].uniques.android !== undefined) {
        sessionStats.usersAndroid.push(stats[i].uniques.android);
      }
    }
  }

  return sessionStats;
};

export const totalAppSessions = (state, getters) => {
  return getters.periodStats && getters.periodStats.length ? getters.periodStats.map((e) => e.sessions).reduce(getSum) : 0;
};

export const totalAppMinutes = (state, getters) => {
  return getters.periodStats && getters.periodStats.length ? getters.periodStats.map((e) => e.minutes).reduce(getSum) : 0;
};

export const totalAppUsers = (state, getters) => {
  return getters.periodStats && getters.periodStats.length ? getters.periodStats.map((e) => e.uniques.total).reduce(getSum) : 0;
};

export const totalAppUsersAndroid = (state, getters) => {
  return getters.periodStats && getters.periodStats.length ? getters.periodStats.map((e) => e.uniques.android).reduce(getSum) : 0;
};

export const totalAppUsersIos = (state, getters) => {
  return getters.periodStats && getters.periodStats.length ? getters.periodStats.map((e) => e.uniques.ios).reduce(getSum) : 0;
};

function getSum(total, num) {
  return total + num;
}
