function initialState() {
  return {
    automationSettings: {
      loading: true,
      hasLibretime: false,
      status: null,
      hasAutoDj: true,
    },
  };
}

let state = initialState();

export { initialState, state };
