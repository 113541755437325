import { initialState } from "./state";

export const setAutoDj = (state, payload) => {
  state.autoDj = payload;
};

export const setPlaylist = (state, payload) => {
  state.autoDj.playlist = payload;
};

export const setShuffle = (state, payload) => {
  state.autoDj.shuffle = payload;
};

export const setLoadingAutoDj = (state, payload) => {
  state.loadingAutoDj = payload;
};

export const setCurrent = (state, payload) => {
  state.current = payload;
};

export const setPreviewURL = (state, payload) => {
  state.previewURL = payload;
};

export const reset = (state) => {
  const s = initialState();

  Object.keys(s).forEach((key) => {
    state[key] = s[key];
  });
};
