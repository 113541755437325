import {
  getAccountCTLStats,
  getAccountAppStats,
  getAccountStreamingStats,
  getAccountRoyaltyStats,
  getStationCTLStats,
  getStationStreamingStats,
  getStationRoyaltyStats,
  getAccountCityStats,
  getStationCityStats
} from "@/api/analytics.api";

export const fetchStreamingAnalytics = async (vuex, analyticsPromises) => {
  const results = await Promise.all(analyticsPromises);

  const regionalStats = results.find((stats) =>
    Object.hasOwn(stats, "country_stats")
  ).country_stats;

  const deviceStats = results.find((stats) =>
    Object.hasOwn(stats, "device_stats")
  ).device_stats;

  let periodStats;

  let periodResults = results.find((stats) =>
    Object.hasOwn(stats, "period_stats")
  );

  if (!periodResults) {
    periodStats = results.find((stats) =>
      Object.hasOwn(stats, "hour_stats")
    ).hour_stats;
  } else {
    periodStats = periodResults.period_stats;
  }

  const royaltyStats = results.find((stats) =>
    Object.hasOwn(stats, "royalty_stats")
  ).royalty_stats;

  vuex.commit("setRegionalStats", regionalStats);
  vuex.commit("setDeviceStats", deviceStats);
  vuex.commit("setPeriodStats", periodStats);
  vuex.commit("setRoyaltyStats", royaltyStats);
};

export const fetchStationStreamingAnalytics = async (vuex, params) => {
  vuex.commit("reset");

  vuex.commit("setParams", params);

  const regionalStatsPromise = getStationStreamingStats(params, "regional");

  const deviceStatsPromise = await getStationStreamingStats(params, "device");

  const royaltyStatsPromise = await getStationRoyaltyStats(params);

  let periodStatsPromise;

  if (params.from_date === params.to_date) {
    vuex.commit("setPeriodType", "day");

    const dayParams = {
      stationKeys: params.stationKeys,
      includeRelays: params.includeRelays,
      groupByMaster: params.groupByMaster,
      offset: params.offset,
      date: params.from_date,
    };

    periodStatsPromise = await getStationStreamingStats(dayParams, "day_stats");
  } else {
    periodStatsPromise = await getStationStreamingStats(params, "period");
  }

  await vuex.dispatch("fetchStreamingAnalytics", [
    regionalStatsPromise,
    deviceStatsPromise,
    periodStatsPromise,
    royaltyStatsPromise
  ]);
};

export const fetchAccountStreamingAnalytics = async (vuex, params) => {
  vuex.commit("reset");

  vuex.commit("setParams", params);

  const account = vuex.rootGetters["Account/account"];

  const regionalStatsPromise = getAccountStreamingStats(
    account.key,
    params,
    "regional"
  );

  const deviceStatsPromise = getAccountStreamingStats(
    account.key,
    params,
    "device"
  );

  const royaltyStatsPromise = getAccountRoyaltyStats(
    params,
    account.key
  );

  let periodStatsPromise;

  if (params.from_date === params.to_date) {
    vuex.commit("setPeriodType", "day");

    const dayParams = {
      offset: params.offset,
      date: params.from_date,
      groupByMaster: params.groupByMaster,
    };

    periodStatsPromise = getAccountStreamingStats(
      account.key,
      dayParams,
      "day"
    );
  } else {
    periodStatsPromise = getAccountStreamingStats(
      account.key,
      params,
      "period"
    );
  }

  await vuex.dispatch("fetchStreamingAnalytics", [
    regionalStatsPromise,
    deviceStatsPromise,
    periodStatsPromise,
    royaltyStatsPromise
  ]);
};

export const fetchAccountCTLAnalytics = async (vuex, params) => {
  vuex.commit("reset");

  vuex.commit("setParams", params);

  const account = vuex.rootGetters["Account/account"];

  let type = "period";

  if (params.from_date === params.to_date) {
    vuex.commit("setPeriodType", "day");

    params = {
      date: params.from_date,
    };

    type = "day";
  }

  const results = await getAccountCTLStats(account.key, params, type);

  vuex.commit(
    "setPeriodStats",
    Object.hasOwn(results, "hour_stats")
      ? results.hour_stats
      : results.period_stats
  );
};

export const fetchAccountAppAnalytics = async (vuex, params) => {
  vuex.commit("reset");

  vuex.commit("setParams", params);

  const account = vuex.rootGetters["Account/account"];

  let type = "period";

  if (params.from_date === params.to_date) {
    vuex.commit("setPeriodType", "day");

    params = {
      date: params.from_date,
      appId: params.appId,
    };

    type = "day";
  }

  const results = await getAccountAppStats(account.key, params, type);

  vuex.commit(
    "setPeriodStats",
    Object.hasOwn(results, "hour_stats")
      ? results.hour_stats
      : results.period_stats
  );
};

export const fetchStationCTLAnalytics = async (vuex, params) => {
  vuex.commit("reset");

  vuex.commit("setParams", params);

  let statsParams = {
    from_date: params.from_date,
    to_date: params.to_date,
  };

  let type = "period";

  if (params.from_date === params.to_date) {
    vuex.commit("setPeriodType", "day");

    statsParams = {
      date: params.from_date,
    };

    type = "day";
  }

  const promises = params.stationKeys.map((stationKey) =>
    getStationCTLStats(stationKey, statsParams, type)
  );

  const results = await Promise.all(promises);

  const stats = results.flat();

  let period_stats;

  if (type !== "period") {
    period_stats = stats
      .filter((stats) => Object.hasOwn(stats, "hour_stats"))
      .flatMap((x) => x.hour_stats)
      .reduce((basket, period) => {
        const index = basket.findIndex(
          (element) => element.hour === period.hour
        );

        if (index !== -1) {
          Object.keys(basket[index].stats).forEach(function (field) {
            basket[index].stats[field] =
              basket[index].stats[field] + period.stats[field];
          });
        } else {
          basket.push(period);
        }
        return basket;
      }, []);

    period_stats.sort((a, b) =>
      a.hour > b.hour ? 1 : b.hour > a.hour ? -1 : 0
    );
  } else {
    period_stats = stats
      .filter((stats) => Object.hasOwn(stats, "period_stats"))
      .flatMap((x) => x.period_stats)
      .reduce((basket, period) => {
        const index = basket.findIndex((element) => element.day === period.day);

        if (index !== -1) {
          Object.keys(basket[index].stats).forEach(function (field) {
            basket[index].stats[field] =
              basket[index].stats[field] + period.stats[field];
          });
        } else {
          basket.push(period);
        }
        return basket;
      }, []);
  }

  vuex.commit("setPeriodStats", period_stats);
};

export const fetchAccountCityAnalytics = async (vuex, { accountId, countryCode, includeOutputs }) => {
  const cityAnalytics = await getAccountCityStats(accountId, countryCode, includeOutputs);

  vuex.commit("setCityStats", cityAnalytics);
};

export const fetchStationCityAnalytics = async (vuex, { mountPoint, countryCode, includeOutputs }) => {
  const cityAnalytics = await getStationCityStats(mountPoint, countryCode, includeOutputs);

  vuex.commit("setCityStats", cityAnalytics);
};

export const exportAnalytics = async (context, [type, data]) => {
  // Wrap each element in double quotes to handle commas, newlines, and quotes
  const csvContent = data
    .map(row =>
      row
        .map(cell =>
          `"${String(cell).replace(/"/g, '""')}"` // Escape quotes within the data
        )
        .join(",")
    )
    .join("\n");

  let encodedUri = `data:text/csv;charset=utf-8,${encodeURIComponent(csvContent)}`;
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);

  let title = `${type}_stats_`;
  title += context.getters.isDayAnalytics
    ? context.getters.params.from_date
    : `${context.getters.params.from_date}_${context.getters.params.to_date}`;
  title += ".csv";

  link.setAttribute("download", title);
  document.body.appendChild(link); // Required for Firefox

  link.click();
  document.body.removeChild(link); // Cleanup after download
};

export const resetAnalytics = async (context) => {
  context.commit("reset");
};
