import * as api from "@/api/portalNotifications.api";

export const fetchPortalNotifications = async ({ commit }) => {
  commit("setNotifications", await api.getAll());
};

export const createPortalNotification = async (vuex, data) => {
  await api.create(data);
};

export const updatePortalNotification = async (vuex, data) => {
  await api.update(data);
};

export const removePortalNotification = async (vuex, id) => {
  await api.remove(id);
};

export const resetPortalNotifications = async (context) => {
  context.commit("reset");
};
