function initialState() {
  return {
    accounts: null,

    account: {
      address: null,
      telephone: null,
      currency: 0,
      job_description: null,
      balance_pending: 0,
      city: null,
      zip: null,
      area: null,
      date_from: null,
      vatid_valid: false,
      comments: null,
      balance_free: 0,
      vatid: null,
      email: "",
      fax: null,
      updated: null,
      company: null,
      credit_card: {},
      key: "",
      date_to: null,
      active: true,
      vat_category: 0,
      rights: [],
      country: null,
      customer_type: 0,
      doy: null,
      balance: 0,
      suspended: false,
    },
  };
}

let state = initialState();

export { initialState, state };
