import advertisingRouter from "@/routes/advertisingRouter";
import stationRouter from "@/routes/stationRouter";
import podcastRouter from "@/routes/podcastRouter";
import analyticsRouter from "@/routes/analyticsRouter";
import subscriptionsRouter from "@/routes/subscriptionsRouter";
import micrositeRouter from "@/routes/micrositeRouter";
import accountFinanceRouter from "@/routes/accountFinanceRouter";

export default [
  {
    path: "/accounts",
    name: "accounts",
    component: () => import("@/views/Accounts/Accounts"),
    meta: {
      pageTitle: "Accounts",
      rule: "owner",
    },
    children: [
      {
        path: "create",
        component: () => import("@/views/Accounts/AccountCreate"),
        name: "account.create",
        props: true,
        meta: {
          pageTitle: "Create account",
          rule: "owner",
        },
      },
      {
        path: ":accountKey",
        component: () => import("@/views/Accounts/Account"),
        props: true,
        meta: {
          pageTitle: "Real Time",
          rule: "owner",
        },
        children: [
          {
            path: "",
            name: "account",
            component: () => import("@/views/Accounts/AccountDashboard.vue"),
            meta: {
              pageTitle: "Dashboard",
              rule: "owner",
            },
          },
          {
            path: "upgrade",
            component: () => import("@/views/Accounts/AccountUpgrade"),
            name: "account.upgrade",
            props: true,
            meta: {
              pageTitle: "Account upgrade",
              rule: "owner",
            },
          },
          ...advertisingRouter,
          ...stationRouter,
          ...podcastRouter,
          ...micrositeRouter,
          {
            path: "people",
            name: "account.people",
            component: () =>
              import("@/views/Accounts/components/AccountPeople/AccountPeople"),
            meta: {
              pageTitle: "Account people",
              rule: "owner",
            },
          },
          ...analyticsRouter,
          ...subscriptionsRouter,
          ...accountFinanceRouter,
          {
            path: "settings",
            name: "account.settings",
            component: () => import("@/views/Accounts/AccountSettings"),
            meta: {
              pageTitle: "Account settings",
              rule: "owner",
            },
          },
        ],
      },
    ],
  },
];
