import * as financeAPI from "@/api/accountFinance.api";

export const checkHasFinanceData = async ({ commit }, accountKey) => {
  const hasFinancialData = await financeAPI.hasData(accountKey);

  commit("setHasFinanceData", hasFinancialData);
};

export const getFinanceData = async ({ commit, getters }, accountKey) => {
  try {
    const financeData = await financeAPI.getData(
      accountKey,
      getters.activeFrequency,
      getters[getters.activeTimeFrame]
    );

    commit("setFinanceData", financeData);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const selectTimeFrame = async ({ commit, dispatch }, value) => {
  try {
    commit("changeActiveTimeFrame", value);

    if (value !== "custom") {
      dispatch("selectCustomTimeFrame", { from: "", to: "" });
      dispatch("selectDataFrequency", "daily");
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const selectDataFrequency = async (context, value) => {
  try {
    context.commit("changeActiveDataFrequency", value);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const selectCustomTimeFrame = async (context, value) => {
  try {
    context.commit("changeCustomTimeFrame", value);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const selectActiveDataType = async (context, type) => {
  try {
    context.commit("changeActiveDataType", type);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchInvoiceSettings = async ({ commit }, accountKey) => {
  try {
    const invoiceSettings = await financeAPI.getInvoiceSettings(accountKey);

    commit("setInvoiceSettings", invoiceSettings);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updateInvoiceSettings = async ({ commit }, data) => {
  const settings = await financeAPI.updateInvoiceSettings(data);

  commit("setInvoiceSettings", settings);
};

export const resetFinance = async ({ commit }) => {
  try {
    commit("reset");
  } catch (e) {
    console.log(e);
    throw e;
  }
};
