function initialState() {
  return {
    user: {
      updated: "",
      rights: [],
      localization: {
        currency: null,
        country: "",
        timezone: "",
      },
      contact_info: null,
      basic_info: {
        gender: "",
        first_name: "",
        last_name: "",
      },
      portal_rights: [],
      authentication: {
        email: "",
      },
      email: "",
      dj_info: {
        radio_personality: null,
        tags: [],
        pretty_url: "",
        image_url: "",
        bio: null,
        nickname: "",
        public: null,
        profile_url: "",
      },
      id: "",
    },
  }
}

let state = initialState()

export {
  initialState,
  state,
}

