import moment from "moment";
import { i18n } from "@/plugins/i18n";

function initialState() {
  const weekDays = [];

  const userLocale = navigator.language || i18n.locale || i18n.fallbackLocale;
  const localeData = moment.localeData(userLocale);
  const firstDayOfWeek = localeData.firstDayOfWeek();

  for (let i = firstDayOfWeek; i < firstDayOfWeek + 7; i++) {
    const dayValue = (i % 7) + 1;
    const dayLabel = moment()
      .day(i % 7)
      .format("dddd");

    weekDays.push({ value: dayValue, label: dayLabel });
  }

  return {
    schedule: {
      id: null,
      podcastKey: "",
      stream: "",
      name: "",
      begin: null,
      end: null,
      timezone: "",
      slots: [
        {
          duration: null,
          daysOfWeek: [],
          start: null,
        },
      ],
    },
    weekDays: weekDays,
  };
}

let state = initialState();

export { initialState, state };
