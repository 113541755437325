import { getPortalLiveStats } from "@/api/portalAnalytics.api";

export const fetchPortalLiveStats = async (context) => {
  const portalLiveStats = await getPortalLiveStats();
  context.commit("setPortalLiveStats", portalLiveStats);
};

export const resetPortalLiveStats = async (context) => {
  context.commit("reset");
};
