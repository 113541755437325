let config = {
  appName: process.env.VUE_APP_NAME,
  appLogo: process.env.VUE_APP_LOGO,
  isRecordPodcastEnabled: process.env.VUE_APP_RECORD_PODCAST_ENABLED === "true",
  isImportPodcastEnabled:process.env.VUE_APP_IMPORT_PODCAST_ENABLED === "true",
  isCreateNewPodcastEnabled: process.env.VUE_APP_CREATE_PODCAST_ENABLED === "true",
  defaultPodcastCreationMethod: process.env.VUE_APP_DEFAULT_PODCAST_CREATION_METHOD,
  isZeno: JSON.parse(process.env.VUE_APP_FEATURE_ZENOTOOLS),
  colors: {
    primary: process.env.VUE_APP_THEME_COLOR_PRIMARY,
    primaryLight: process.env.VUE_APP_THEME_COLOR_PRIMARY_LIGHT,
    success: process.env.VUE_APP_THEME_COLOR_SUCCESS,
    danger: process.env.VUE_APP_THEME_COLOR_DANGER,
    warning: process.env.VUE_APP_THEME_COLOR_WARNING,
  },
  automation: JSON.parse(process.env.VUE_APP_FEATURE_AUTOMATION),
  libreTimeUrl: process.env.VUE_APP_LIBRETIME_URL,
  nowPlayingEventSourceURL: process.env.VUE_APP_NOW_PLAYING_EVENT_SOURCE_URL,
  imageProxyURL: process.env.VUE_APP_IMAGE_PROXY_URL,
  podcastFeedURL: process.env.VUE_APP_PODCAST_FEED_URL,
  copyrightText: process.env.VUE_APP_COPYRIGHT_TEXT,
  copyrightURL: process.env.VUE_APP_COPYRIGHT_URL,
  termsText: process.env.VUE_APP_TERMS_TEXT,
  termsURL: process.env.VUE_APP_TERMS_URL,
  isPlanCustomStationsEnabled: process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_CUSTOM_STATIONS_ENABLED ? JSON.parse(process.env.VUE_APP_STRIPE_SUBSCRIPTION_ACCOUNT_CUSTOM_STATIONS_ENABLED) : false,
};

export { config };
