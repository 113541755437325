function initialState() {
  return {
    accountRoles: [
      "owner", "admin"
    ],

    stationRoles: [
      "owner", "admin", "staff"
    ]
  }
}

let state = initialState()

export {
  initialState,
  state
}



