import SubscriptionService from "@/services/SubscriptionService";

export const fetchStationSubscriptions = async ({ commit }, stationId) => {
  const subscriptions = await SubscriptionService.getStationSubscriptions(
    stationId
  );

  commit("setSubscriptionList", subscriptions);

  return false;
};

export const getStationSubscription = async (vuex, { stationId, planId }) => {
  const subscription = await SubscriptionService.getStationSubscription(
    stationId,
    planId
  );

  if (typeof subscription.id !== "undefined") {
    return subscription;
  }

  return false;
};
