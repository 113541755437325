import { config } from "/app.config";

export default config.isZeno
  ? [
      {
        path: "microsite",
        name: "account.microsite",
        component: () => import("@/views/Microsite/Microsite"),
        meta: {
          pageTitle: "Manage microsite",
          rule: "owner",
        },
      },
    ]
  : [];
