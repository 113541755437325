import { config } from "/app.config";

export default config.automation
  ? [
      {
        name: "station.automation",
        path: "automation",
        component: () => import("@/views/Stations/StationAutomation.vue"),
        meta: {
          pageTitle: "Station Automation Software",
          parent: "stations",
          rule: "owner",
        },
      },
    ]
  : [];
