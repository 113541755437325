import toolsClient from "@/api/toolsClient";
import axios from "axios";

let tokenData = {};
let libraryClient = {};
let uploaderClient = {};

function getLibraryClient(tokenData) {
  let config = {
    baseURL: tokenData.url,
    headers: {
      "X-Auth-Token": tokenData.token,
    },
  };

  return axios.create(config);
}

function getUploaderClient(tokenData) {
  let config = {
    baseURL: tokenData.uploader,
    headers: {
      "X-Auth-Token": tokenData.token,
    },
  };

  return axios.create(config);
}

export const getLibrary = async (accountKey) => {
  let response = await toolsClient.get(
    `accounts/${accountKey}/libraries/podcast/token`
  );

  tokenData = response.data;

  libraryClient = getLibraryClient(tokenData);
  uploaderClient = getUploaderClient(tokenData);

  return response.data;
};

export const getPodcasts = async () => {
  const response = await libraryClient.get(`api/podcasts/`);

  return response.data;
};

export const getPodcast = async (podcastKey) => {
  const response = await libraryClient.get(`api/podcasts/${podcastKey}`);

  return response.data;
};

export const storePodcast = async (data) => {
  const response = await libraryClient.post(`/api/podcasts/`, data);

  return response.data;
};

export const updatePodcast = async (data) => {
  const response = await libraryClient.put(`/api/podcasts/${data.key}`, data);

  return response.data;
};

export const deletePodcast = async (podcastKey) => {
  const response = await libraryClient.delete(`/api/podcasts/${podcastKey}`);

  return response.data;
};

export const getImageUploadURL = async () => {
  const response = await libraryClient.get(`/images/get_upload_url`);

  return response.data.upload_url;
};

export const uploadImage = async (file) => {
  let data = new FormData();
  data.append("cover_image", file);

  let uploadURL = await getImageUploadURL();

  const response = await libraryClient.post(uploadURL, data);

  return response.data;
};

export const importPodcast = async (url) => {
  const response = await libraryClient.post(`/api/podcasts/import`, {
    feed_url: url,
  });

  return response.data;
};

export const getTaskStatus = async (task) => {
  const response = await libraryClient.get(`/tasks/${task}`);

  return response.data;
};

export const podcastPortalSearch = async ({
  query = "",
  limit = 10,
  offset = 0,
}) => {
  const response = await toolsClient.get(
    `/portal/search/podcasts?limit=${limit}&offset=${offset}&search=${query}`
  );

  return response.data;
};

export { libraryClient, uploaderClient };
