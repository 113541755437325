import { initialState } from "./state";
import { getOptimizedImage } from "@/helpers";

export const setEpisodes = (state, payload) => {
  let episodes = payload;

  episodes = episodes.map((episode) => {
    if (typeof episode.image !== "undefined" && episode.image.length) {
      episode.logo = getOptimizedImage(
        "https://" + episode.image + "?u=" + Date.now(),
        170,
        170,
      );
    } else {
      episode.logo = false;
    }

    return episode;
  });

  state.episodes = episodes;
};

export const setEpisode = (state, payload) => {
  let episode = payload;

  if (typeof episode.image !== "undefined" && episode.image.length) {
    episode.logo = getOptimizedImage(
      "https://" + episode.image + "?u=" + Date.now(),
      170,
      170,
    );
  }

  state.episode = episode;
};

export const resetEpisode = (state) => {
  state.episode = {
    subtitle: null,
    image_key: null,
    file_url: "",
    image: "",
    duration: 0,
    guid: "",
    size: 0,
    title: "",
    upload_status: "",
    rich_description: null,
    description: "",
    tags: [],
    season: 0,
    link: null,
    key: "",
    podcast: "",
    episode: 0,
    episode_type: null,
    author: "",
    explicit: false,
    summary: null,
    publish_date: "",
    duration_hms: "",
    block: false,
  };
};

export const reset = (state) => {
  const s = initialState();

  Object.keys(s).forEach((key) => {
    state[key] = s[key];
  });
};
