import {
  getStationAudioAdsStatus,
  updateStationAudioAdsStatus,
  createStationAudioAdsAgreement,
  getStationAudioAdsAgreement,
} from "@/services/StationService"

export const fetchAudioAdsStatus = async ({ commit }, stationKey) => {
  const response = await getStationAudioAdsStatus(stationKey)

  commit("setAudioAdsSettings", response)
}

export const updateAudioAdsStatus = async ({ commit }, { stationKey, hasAudioAds, applyToRelays }) => {
  const response = await updateStationAudioAdsStatus(stationKey, hasAudioAds, applyToRelays)

  commit("setAudioAdsSettings", response)
}

export const createAudioAdsAgreement = async ({ commit }, stationKey) => {
  const response = await createStationAudioAdsAgreement(stationKey)

  commit("setAudioAdsAgreement", response)
}

export const fetchAudioAdsAgreement = async ({ commit }, { stationKey, include_doc }) => {
  const response = await getStationAudioAdsAgreement(stationKey, include_doc)

  commit("setAudioAdsAgreement", response)
}
