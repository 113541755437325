import { initialState } from "./state";

export const setAccountSubscriptions = (state, payload) => {
  state.accountSubscriptions = payload;
};

export const setAccountPlans = (state, payload) => {
  state.accountPlans = payload;
};

export const reset = (state) => {
  const s = initialState();

  Object.keys(s).forEach((key) => {
    state[key] = s[key];
  });
};
