import { initialState } from "./state";

export const setAutomationSettings = (state, payload) => {
  state.automationSettings = payload;
};

export const reset = (state) => {
  const s = initialState();

  Object.keys(s).forEach((key) => {
    state[key] = s[key];
  });
};
