import AccountService from "@/services/AccountService";

export const fetchAccountCustomLimits = async ({ commit }, id) => {
    const accountCustomLimits = await AccountService.getAccountCustomLimits(id);
  
    commit("setAccountCustomLimits", accountCustomLimits);
  
    return accountCustomLimits;
  };
  
  export const updateAccountCustomLimits = async ({ commit }, data) => {
    const accountCustomLimits = await AccountService.updateAccountCustomLimits(data);
  
    commit("setAccountCustomLimits", accountCustomLimits);
  
    return accountCustomLimits;
  };