import toolsClient from "@/api/toolsClient";

export const restart = async (id) => {
  await toolsClient.post(`stations/${id}/server/restart/`);
};

export const start = async (id) => {
  await toolsClient.post(`stations/${id}/server/start/`);
};

export default {
  restart,
  start,
  stop,
};
