function initialState() {
  return {
    hasFinanceData: null,
    financeData: null,

    invoiceSettings: {
      accountKey: "",
      referenceNumber: "",
      mount: "",
      cpmRate: "",
      brdEmail: "",
      accountName: ""
    },

    customTimeFrame: {
      from: "",
      to: ""
    },

    dataTypes: [
      {
        value: "total",
        selected: true
      },
      {
        value: "audio",
        selected: false
      },
      {
        value: "display",
        selected: false
      }
    ],

    timeFrames: [
      {
        label: "Yesterday",
        value: "yesterday",
        selected: false
      },
      {
        label: "This Week",
        value: "thisWeek",
        selected: false
      },
      {
        label: "Last Week",
        value: "lastWeek",
        selected: false
      },
      {
        label: "This Month",
        value: "thisMonth",
        selected: true
      },
      {
        label: "Last Month",
        value: "lastMonth",
        selected: false
      },
      {
        label: "Custom",
        value: "custom",
        selected: false
      }
    ],

    dataFrequencies: [
      {
        label: "Daily",
        value: "daily",
        selected: false
      },
      {
        label: "Monthly",
        value: "monthly",
        selected: true
      }
    ]
  }
}

let state = initialState()

export {
  initialState,
  state
}
