export const isAccountOwner = (state, getters, rootState, rootGetters) => {
  const account = rootGetters["Account/account"]

  return account.rights.includes('owner')
}

export const isAccountAdmin = (state, getters, rootState, rootGetters) => {
  if (getters.isAccountOwner) {
    return true
  }

  const account = rootGetters["Account/account"]

  return account.rights.includes('admin')
}
