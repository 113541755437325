export const check = ({getters}, role) => {
  return getters[role]
}

export const checkAny = ({getters}, roles) => {
  for (const role of roles) {
    if (getters[role]) {
      return true;
    }
  }

  return false
}
