<template>
  <div class="relative">
    <select
      :value="value"
      @input="$emit('input', $event.target.value)"
      :placeholder="placeholder"
      class="custom-select"
    >
      <option v-for="option in options" :key="option" :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    value: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
  },
};
</script>

<style scoped>
.custom-select {
  background-color: #fff;
  border: 1px solid #d2d6dc;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  outline: none;
}
</style>
