import {initialState} from "@/store/modules/account/state"

export const setCtlNumbers = (state, payload) => {
  state.numbers = payload
}

export const setCtlWelcome = (state, payload) => {
  state.welcome = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach(key => {
    state[key] = s[key]
  })
}
