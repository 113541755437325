<template>
  <div class="flex items-center mr-4">
    <span class="pr-2">{{ label }}</span>
    <custom-select
      v-model="pageSize"
      :options="options"
      placeholder="Page size"
    />
  </div>
</template>
<script>
export default {
  name: "PageSizeSelect",
  props: {
    value: {
      type: Number,
      required: true,
    },
    options: {
      type: Array,
      default: () => [10, 50, 100, 500, 1000],
    },
    label: {
      type: String,
      default: "Items per page",
    },
  },
  computed: {
    pageSize: {
      get() {
        return this.value || this.options[0];
      },
      set(newValue) {
        this.$emit("input", Number(newValue));
      },
    },
  },
};
</script>
