export const ctlNumbers = (state) => {
  return state.numbers.map(number => {
    number.formated = number.did.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")

    return number
  })
}

export const hasCTL = state => state.numbers.length > 0
export const welcome = state => state.welcome

export const ctlPhone = (state, getters) => {
  return getters.ctlNumbers.find(did => did.main === true)
}
