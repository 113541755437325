import {initialState} from "./state"

export const setPlayingStatus = (state, payload) => {
  state.playingStatus = payload
}

export const setPlayerStation = (state, payload) => {
  state.playerStation = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach(key => {
    state[key] = s[key]
  })
}
