import {initialState} from "./state"


export const setAccountCustomLimits = (state, payload) => {
  state.accountCustomLimits = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach(key => {
    if (key !== "accounts") {
      state[key] = s[key]
    }
  })
}
