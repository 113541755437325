function initialState() {
  return {
    playingStatus: false,

    playerStation: {
      key: "",
      name: "",
      route: "",
      streamBase: "",
      streamName: "",
    },
  }
}

let state = initialState()

export {
  initialState,
  state
}
