import stationPodcastRouter from "@/routes/stationPodcastRouter";
import stationAutomationRouter from "@/routes/stationAutomationRouter";

export default [
  {
    path: "stations",
    component: () => import("@/views/Stations/Stations.vue"),
    children: [
      {
        path: "",
        name: "stations",
        component: () => import("@/views/Stations/StationList.vue"),
        meta: {
          pageTitle: "Station list",
          rule: "owner",
        },
      },
      {
        path: "deleted",
        name: "stations.deleted",
        component: () => import("@/views/Stations/StationDeletedList.vue"),
        meta: {
          pageTitle: "Station Deleted List",
          parent: "stations",
          rule: "owner",
        },
      },
      {
        path: "create",
        component: () => import("@/views/Stations/StationCreate.vue"),
        name: "station.create",
        props: true,
        meta: {
          pageTitle: "Create Station",
          parent: "stations",
          rule: "owner",
        },
      },
      {
        path: ":stationKey",
        component: () => import("@/views/Stations/Station.vue"),
        name: "station",
        props: true,
        meta: {
          pageTitle: "Station info",
          parent: "stations",
          rule: "owner",
        },
        children: [
          ...stationAutomationRouter,
          ...stationPodcastRouter,
          {
            name: "station.widget",
            path: "widget",
            component: () => import("@/views/Stations/StationWidget.vue"),
            meta: {
              pageTitle: "Station widget",
              parent: "stations",
              rule: "owner",
            },
          },
          {
            name: "station.ctl",
            path: "ctl",
            component: () => import("@/views/Stations/StationCTL.vue"),
            meta: {
              pageTitle: "Call-To-Listen",
              parent: "stations",
              rule: "owner",
            },
          },
          {
            name: "station.settings",
            path: "settings",
            component: () => import("@/views/Stations/StationSettings.vue"),
            meta: {
              pageTitle: "Station settings",
              parent: "stations",
              rule: "owner",
            },
            children: [
              {
                name: "station.stream",
                path: "stream",
                component: () =>
                  import("@/views/Stations/Settings/StationSettingsStream.vue"),
                meta: {
                  pageTitle: "General",
                  parent: "stations",
                  rule: "owner",
                },
              },
              {
                name: "station.info",
                path: "info",
                component: () =>
                  import("@/views/Stations/Settings/StationSettingsInfo.vue"),
                meta: {
                  pageTitle: "Station Info",
                  parent: "stations",
                  rule: "owner",
                },
              },
              {
                name: "station.people",
                path: "people",
                component: () =>
                  import("@/views/Stations/Settings/StationSettingsPeople.vue"),
                meta: {
                  pageTitle: "People",
                  parent: "stations",
                  rule: "owner",
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
