export const countries = (state) => {
  return state.countries;
};

export const countriesSelect = (state) => {
  return state.countries.map((country) => {
    return {
      country_code: country.iso,
      value: country.name,
      caption: country.displayName,
    };
  });
};
