import * as api from "@/api/episode.api";

export const getEpisodes = async ({ commit }, { podcastKey, params }) => {
  try {
    let episodes = await api.getEpisodes(podcastKey, params);

    commit("setEpisodes", episodes.data);

    return episodes;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getEpisode = async ({ commit }, data) => {
  try {
    let episode = await api.getEpisode(data);

    commit("setEpisode", episode);

    return episode;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const updateEpisode = async ({ commit }, data) => {
  try {
    let episode = await api.updateEpisode(data);

    commit("setEpisode", episode);

    return episode;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const uploadEpisodeFile = async ({ rootGetters }, { podcast, file }) => {
  try {
    let token = rootGetters["Podcast/library"];

    return await api.uploadEpisodeFile(token, podcast, file);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getEpisodeUploadingStatus = async (vuex, task) => {
  try {
    return await api.getEpisodeUploadingStatus(task);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const searchEpisodes = async ({ commit }, { podcastKey, search }) => {
  try {
    let episodes = await api.searchEpisodes(podcastKey, search);

    commit("setEpisodes", episodes);

    return episodes;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteEpisode = async (vuex, episode) => {
  try {
    await api.deleteEpisode(episode);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const resetEpisode = async ({ commit }) => {
  try {
    commit("resetEpisode");
  } catch (e) {
    console.log(e);
    throw e;
  }
};
