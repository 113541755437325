import { i18n } from "@/plugins/i18n";

export default [
  {
    path: "episodes",
    component: () => import("@/views/Podcasts/Episodes/PodcastEpisodes.vue"),
    children: [
      {
        path: "",
        name: "episodes",
        component: () => import("@/views/Podcasts/Episodes/PodcastEpisodeList.vue"),
        meta: {
          pageTitle: i18n.t("episodes.page_title"),
          parent: "podcasts",
          rule: "owner"
        }
      },
      {
        path: ":episodeKey",
        component: () => import("@/views/Podcasts/Episodes/PodcastEpisode.vue"),
        name: "episode",
        props: true,
        meta: {
          pageTitle: "Episode info",
          icon: "InfoIcon",
          parent: "podcasts",
          rule: "owner"
        },
        children: [
          {
            name: "episode.edit",
            path: "edit",
            props: true,
            component: () => import("@/views/Podcasts/Episodes/PodcastEpisodeEdit.vue"),
            meta: {
              pageTitle: "Edit episode",
              icon: "MusicIcon",
              parent: "podcasts",
              rule: "owner"
            }
          }
        ]
      }
    ]
  }
]
