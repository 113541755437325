import { initialState } from "./state"

export const setUploadQueue = (state, payload) => {
  state.uploadQueue = payload
}

export const addToUploadQueue = (state, payload) => {
  state.uploadQueue = state.uploadQueue.concat(payload)
}

export const updateQueuedMedia = (state, { media, fields }) => {
  const index = state.uploadQueue.findIndex((m) => m.file.name === media.file.name)

  if (state.uploadQueue[index]) {
    for (const [key, value] of Object.entries(fields)) {
      state.uploadQueue[index][key] = value
    }
  }
}

export const setUploadAbortController = (state, payload) => {
  state.uploadAbortController = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach((key) => {
    state[key] = s[key]
  })
}
