import { initialState } from "./state";
import { getOptimizedImage } from "@/helpers";

export const setStation = (state, payload) => {
  let station = payload;

  station.logo = getOptimizedImage(
    process.env.VUE_APP_TOOLS_SERVICE_URL +
      `/content/stations/${station.key}/image/?u=` +
      Date.now(),
    170,
    170,
  );

  station.background = getOptimizedImage(
    process.env.VUE_APP_TOOLS_SERVICE_URL +
      `/content/stations/${station.key}/microsite/background_image/?u=` +
      Date.now(),
    420,
    300,
  );

  state.station = station;
};

export const setMasterLink = (state, payload) => {
  state.masterLink = payload;
};

export const setStreamConfig = (state, payload) => {
  state.streamConfig = payload;
};

export const resetStation = (state) => {
  state.station = {
    exclusive: false,
    activation_status: 0,
    station_service_type: 0,
    searchable: false,
    pretty_url: "",
    activated: null,
    server_status: {
      status: 0,
      live: true,
      radio_server_status: 0,
    },
    environment: 0,
    library_guid: null,
    type: 0,
    public: false,
    updated: "",
    description: "",
    show_tracks_playing: true,
    tags: [],
    organization_size: 0,
    reports_settings: 0,
    default_type: 0,
    default_timezone: "",
    key: "",
    streambase: "",
    radio_server_settings: {
      relay_url: null,
      call_in_allowed: false,
      failover_settings: [],
      interrupt_master: null,
      burst: 0,
      allow_secure: false,
      fingerprint_category: null,
      mix_offset: 0,
      studio_allowed: false,
      mp4: false,
      sample_rate: 0,
      project_type: 0,
      media_type: "",
      mix_speed: 0,
      bitrate: 0,
      metadata: "",
    },
    name: "",
    created: "",
    country: "",
    streamname: "",
    suspended: false,
    zosa: false,
    organization_type: 0,
  };
};

export const reset = (state) => {
  const s = initialState();

  Object.keys(s).forEach((key) => {
    state[key] = s[key];
  });
};
