import * as api from "@/api/portalChangelog.api";

export const fetchPortalChangelog = async ({ commit }) => {
  commit("setChangelog", await api.getAll());
};

export const createPortalChangelogItem = async (vuex, data) => {
  await api.create(data);
};

export const updatePortalChangelogItem = async (vuex, data) => {
  await api.update(data);
};

export const removePortalChangelogItem = async (vuex, id) => {
  await api.remove(id);
};

export const resetPortalChangelog = async (context) => {
  context.commit("reset");
};
