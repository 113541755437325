function initialState() {
  return {
    profilePosts: []
  }
}

let state = initialState()

export {
  initialState,
  state
}
