var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"vs-component",attrs:{"data-text":_vm.text}},_vm.listeners),[_c('button',_vm._b({ref:"item",staticClass:"vs-select--item",class:{
      activex: _vm.$parent.parent.multiple
        ? _vm.getValue.indexOf(_vm.value) != -1
        : _vm.getValue == _vm.value,
      'con-icon': _vm.$parent.parent.multiple,
      disabledx: _vm.disabledx,
    },style:(_vm.styles),attrs:{"disabled":_vm.disabled,"name":"button","type":"button"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"backspace",undefined,$event.key,undefined))return null;$event.preventDefault();return _vm.backspace.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.navigateOptions('next')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.navigateOptions('prev')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.clickOption()}]}},'button',_vm.$attrs,false),[(_vm.$parent.parent.multiple)?_c('vs-icon',{staticClass:"icon-item vs-select--item-icon",attrs:{"icon":"check_circle"}}):_vm._e(),_c('span',{style:(_vm.styles),domProps:{"innerHTML":_vm._s(_vm.getText)}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }