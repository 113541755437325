function initialState() {
    return {
      accountCustomLimits: {
        allowedStationsCount: null,
        allowedPodcastsCount: null,
        allowedTracksCount: null,
        allowedBitRateLimit: null,
      },
    };
  }
  
  let state = initialState();
  
  export { initialState, state };
  