import Vue from "vue"
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from "vee-validate/dist/vee-validate.full"
import {size} from "vee-validate/dist/rules"
import pL from 'js-regex-pl';

setInteractionMode("passive")

let validateImage = function (file, minDimensions, maxDimensions) {
  let URL = window.URL || window.webkitURL

  return new Promise(function (resolve) {
    let image = new Image()

    image.onerror = function () {
      return resolve(false)
    }

    image.onload = function () {
      let valid = true

      if (minDimensions) {
        if (minDimensions.width && this.width < minDimensions.width) {
          valid = false
        }

        if (minDimensions.height && this.height < minDimensions.height) {
          valid = false
        }
      }

      if (maxDimensions) {
        if (maxDimensions.width && this.width > maxDimensions.width) {
          valid = false
        }

        if (maxDimensions.height && this.height > maxDimensions.height) {
          valid = false
        }
      }

      return resolve(valid)
    }

    image.src = URL.createObjectURL(file)
  })
}

extend("fileLimit", {
  validate(value, args) {
    if (value.length <= parseInt(args.fileLimit)) {
      return true
    }
    return "You have exceeded the maximum number of tracks allowed for your plan."
  },
  params: ["fileLimit"],
  message: "Tracks limit exceeded."
})

extend("dimensions", {
  validate(value, args) {

    let minDimensions = {
      width: null,
      height: null
    }

    let min = args.min.split("x")

    minDimensions = {
      width: min[0],
      height: min[1]
    }

    let maxDimensions

    if (args.max) {
      maxDimensions = {
        width: null,
        height: null
      }

      let max = args.max.split("x")

      maxDimensions = {
        width: max[0],
        height: max[1]
      }
    }

    return Promise.resolve(validateImage(value[0], minDimensions, maxDimensions))
      .then(valid => {
        return valid
      })
      .catch(() => {
        return false
      })
  },
  params: ["min", "max"],
  message: "Invalid image size."
})

extend("url", {
  validate: (str) => {
    let pattern = new RegExp("^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", "i") // fragment locator
    return !!pattern.test(str)
  },
  message: "The {_field_} field must be a valid URL"
})

extend("pretty_url", {
  validate: value => {
    let strongRegex = new RegExp("^([A-Za-z0-9\\-\\_]+)$")
    return strongRegex.test(value)
  },
  message: "The {_field_} should be at least 6 characters. Only letters, numbers, dashes and underscores are allowed."
})

extend("welcome_prompt_size", {
  ...size,
  message: "The {_field_} field size must be less than 10MB"
})

extend("station_name", {
  validate: value => {
    let strongRegex = new RegExp(`^([${pL}0-9\\.\\-\\_\\(\\) ]+)$`)
    return strongRegex.test(value)
  },
  message: "The station name should be at least 6 characters. Only letters, numbers, spaces, dashes, dots and underscores are allowed."
})

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)
