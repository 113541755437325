import toolsClient from "@/api/toolsClient";

export const setAuth = (state, auth) => {
  let token = auth.token;

  toolsClient.defaults.headers.common = {
    Authorization: "Bearer " + token,
  };

  state.auth = auth;
};

export const logout = (state) => {
  window.auth.logout({ redirectUri: process.env.VUE_APP_BASE_URL });

  state.auth = {};
};
