import axios from "axios"

let config = {
  baseURL: "https://api.tapfiliate.com/1.6/",
  headers: {
    "Content-Type": "application/json",
    "Api-Key": process.env.VUE_APP_TAPFILIATE_API_KEY
  }
}

const client = axios.create(config)

export const getClickId = async referralCode => {
  try {
    let response = await client.post("clicks/", {
      "referral_code": referralCode
    })

    return response.data.id
  } catch (e) {
    return null
  }
}

export const createCustomer = async (clickId, customerId, metaData) => {
  try {
    let response = await client.post("customers/", {
      "click_id": clickId,
      "customer_id": customerId,
      "meta_data": metaData,
      "status": "new"
    })

    return response.data
  } catch (e) {
    return null
  }
}
