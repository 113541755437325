const notification = async (message, title, color = "success", time = 8000, position = "top-right") => {
  const {notify} = await import("@/bootstrap")

  notify({
    color: color,
    position: position,
    time: time,
    title: title,
    text: message
  })
}

export const notificationSuccess = async (message, title, time = 8000, position) => {
  await notification(message, title, "success", time, position)
}

export const notificationError = async (message, title, time = 8000, position) => {
  await notification(message, title, "danger", time, position)
}

export const notificationUnexpectedError = async (message = "We keep track of there errors, but feel free to contact us if refreshing doesn't fix things.", title = "Something went wrong") => {
  await notificationError(message, title, 15000)
}
