import toolsClient from "@/api/toolsClient"

export const getAutomationSettings = async (stationKey) => {
  try {
    const response = await toolsClient.get(`stations/${stationKey}/libretime`)

    return response.data
  } catch (e) {
    console.error("Error fetching automation settings for station:", stationKey, e)
    throw e
  }
}

export const makeAutomationRequest = async (stationKey, request) => {
  try {
    const response = await toolsClient.post(`stations/${stationKey}/libretime/request`, {
      action: request ? "enable" : "disable",
    })

    return response.data
  } catch (e) {
    console.error("Error making automation request for station:", stationKey, e)
    throw e
  }
}
