import StationService from "@/services/StationService";

/**
 * ================================
 * STATIONS
 * ================================
 */

export const resetStation = async ({ commit }) => {
  commit("Autodj/reset", [], { root: true });
  commit("Ctl/reset", [], { root: true });
  commit("StationAutomation/reset", [], { root: true });
  commit("StationPeople/reset", [], { root: true });
  commit("StationAudioAds/reset", [], { root: true });

  commit("reset");
};

export const getStation = async ({ commit }, id) => {
  const station = await StationService.getStation(id);

  commit("setStation", station);

  return station;
};

export const updateStation = async ({ commit }, data) => {
  const station = await StationService.updateStation(data);

  commit("setStation", station);

  return station;
};

export const suspendStation = async ({ commit }, data) => {
  const station = await StationService.suspendStation(data);

  commit("setStation", station);

  return station;
};

export const reactivateStation = async ({ commit }, data) => {
  const station = await StationService.reactivateStation(data);

  commit("setStation", station);

  return station;
};
/**
 * ================================
 * STATION MASTER LINK
 * ================================
 */
export const getMasterLink = async ({ commit }, id) => {
  const result = await StationService.getStationMasterLink(id);

  commit("setMasterLink", result);

  return result;
};

export const updateMasterLink = async ({ commit }, { id, data }) => {
  const result = await StationService.updateStationMasterLink(id, data);

  commit("setMasterLink", result);

  return result;
};

export const resetMasterLink = async ({ commit }, id) => {
  const result = await StationService.resetStationMasterLink(id);

  commit("setMasterLink", result);

  return result;
};
