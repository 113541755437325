import { config } from "/app.config";
import { i18n } from "@/plugins/i18n";

export default config.isZeno || config.appName === "Aiir"
  ? [
      {
        path: "subscriptions",
        name: "subscriptions",
        component: () => import("@/views/Subscriptions/Subscriptions.vue"),
        meta: {
          pageTitle: "Subscriptions",
          icon: "BarChart2Icon",
          rule: "owner",
        },
        children: [
          {
            path: "account",
            component: () =>
              config.appName === "Aiir"
                ? import("@/views/Aiir/Subscriptions/SubscriptionsAccount.vue")
                : import("@/views/Subscriptions/SubscriptionsAccount.vue"),
            name: "subscriptions.account",
            meta: {
              pageTitle:  i18n.t("subscriptions.page_title"),
              parent: "subscriptions",
              icon: "StarIcon",
              rule: "owner",
            },
          },
          {
            path: "billing",
            component: () =>
              import(
                "@/views/Subscriptions/SubscriptionsAccount/SubscriptionBilling.vue"
              ),
            name: "subscriptions.billing",
            meta: {
              pageTitle: "Billing",
              parent: "subscriptions",
              icon: "StarIcon",
              rule: "owner",
            },
          },
          {
            path: "archive",
            component: () =>
              import("@/views/Subscriptions/SubscriptionsArchive.vue"),
            name: "subscriptions.archive",
            meta: {
              pageTitle: "Legacy Plans",
              parent: "subscriptions",
              icon: "InfoIcon",
              rule: "owner",
            },
          },
        ],
      },
    ]
  : [];
