function initialState() {
  return {
    people: [],
    invites: [],
  };
}

let state = initialState();

export { initialState, state };
