import * as stationAPI from "@/api/stations.api";
import * as ctlAPI from "@/api/ctl.api";

export const getCtlNumbers = async (vuex, stationKey) => {
  let numbers = await stationAPI.fetchToolsDIDs(stationKey);

  vuex.commit("setCtlNumbers", numbers);
  vuex.commit("setCtlWelcome", "");

  return numbers;
};

export const getCtlWelcomePrompt = async ({ commit }, stationKey) => {
  const welcomePrompt = await ctlAPI.getWelcomePrompt(stationKey);

  if (welcomePrompt) {
    commit("setCtlWelcome", welcomePrompt);
  }

  return welcomePrompt;
};

export const updateWelcomePrompt = async (
  { dispatch },
  { stationKey, file }
) => {
  await ctlAPI.uploadWelcomePrompt(stationKey, file);

  return await dispatch("getCtlWelcomePrompt", stationKey);
};
