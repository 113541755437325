import axios from "axios";
import { notificationError } from "@/common/helpers/notifications.helper";
import toolsClient from "@/api/toolsClient";

export const getToken = async (stationKey) => {
  const response = await toolsClient.get(
    `stations/${stationKey}/ctl/auth_token`
  );

  return response.data;
};

const getCTLClient = async (stationKey) => {
  const tokenData = await getToken(stationKey);

  const config = {
    baseURL: `https://ctl.zenomedia.com/api/stations/${tokenData.stationId}/prompts/welcome`,
    headers: {
      Authorization: tokenData.token,
    },
  };

  let ctlClient = axios.create(config);

  ctlClient.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      console.log("[CTL] Request error", error.message, error.response);
      return Promise.reject(error);
    }
  );

  ctlClient.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response) {
        // console.log("The request was made and the server responded with an error.")
        console.log("[CTL]", error.message, error.response);
      } else if (error.request) {
        // console.log("The request was made but no response was received.")
        console.log("[CTL]", error.message);
        notificationError(
          "Please check your internet connection, refresh the page and try again.",
          "Network error occurred"
        );
      } else {
        // console.log("Something happened in setting up the request that triggered an error.")
        console.log("[CTL]", error.message);
      }
      // console.log(error.config)

      return Promise.reject(error);
    }
  );

  return ctlClient;
};

export const uploadWelcomePrompt = async (stationKey, file) => {
  try {
    const ctlClient = await getCTLClient(stationKey);

    let data = new FormData();

    data.append("file", file);

    let response = await ctlClient.put("", data);

    return response.data;
  } catch (e) {
    throw new Error("Failed to upload Welcome Prompt");
  }
};

export const getWelcomePrompt = async (stationKey) => {
  try {
    const ctlClient = await getCTLClient(stationKey);

    let response = await ctlClient.head("");

    return response.headers.location;
  } catch (e) {
    return null;
  }
};

export const getCTLRequestStatus = async (stationKey) => {
  const response = await toolsClient.get(`stations/${stationKey}/ctlRequest`);

  return response.data;
};

export const updateCTLRequestStatus = async (stationKey, isEnable) => {
  const response = await toolsClient.post(`stations/${stationKey}/ctlRequest`, {
    action: isEnable ? "enable" : "disable",
  });

  return response.data;
};
