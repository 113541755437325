export const accountPlanFeatures = (state) => {
  return state.accountPlanFeatures;
};

export const allowedStationsCount = (state) => {
  return state.accountPlanFeatures?.allowedStationsCount;
};

export const allowedPodcastsCount = (state) => {
  return state.accountPlanFeatures?.allowedPodcastsCount;
};

export const allowedTracksCount = (state, getters, rootState, rootGetters) => {
  const isPortalAdmin = rootGetters["Profile/isPortalAdmin"];

  if (isPortalAdmin) {
    return 10000;
  }

  return state.accountPlanFeatures?.allowedTracksCount;
};

export const allowedBitRateLimit = (state, getters, rootState, rootGetters) => {
  const isPortalAdmin = rootGetters["Profile/isPortalAdmin"];

  if (isPortalAdmin) {
    return 320000;
  }

  return state.accountPlanFeatures?.allowedBitRateLimit;
};

export const canCreateStation = (state, getters, rootState, rootGetters) => {
  const isPortalAdmin = rootGetters["Profile/isPortalAdmin"];

  if (isPortalAdmin) {
    return true;
  }

  if (!getters.accountPlanFeatures) {
    return null;
  }

  const masters = rootGetters["AccountStations/masters"];

  if (masters === null) {
    return null;
  }

  return masters.length < getters.allowedStationsCount;
};

export const canCreatePodcast = (state, getters, rootState, rootGetters) => {
  const isPortalAdmin = rootGetters["Profile/isPortalAdmin"];

  if (isPortalAdmin) {
    return true;
  }

  const podcasts = rootGetters["Podcast/podcasts"];

  if (!getters.accountPlanFeatures || podcasts === null) {
    return null;
  }

  return podcasts.length < getters.allowedPodcastsCount;
};
