import { initialState } from "./state";

export const setAccountStations = (state, payload) => {
  state.accountStations = payload;
};

export const resetStations = (state) => {
  state.accountStations = [];
};


export const setAccountDeletedStations = (state, payload) => {
  state.accountDeletedStations = payload;
};

export const resetDeletedStations = (state) => {
  state.accountDeletedStations = [];
};


export const setUndeleteStation = (state, payload) => {
  state.undeleteStation = payload;
  console.log("setUndeleteStation: ", state.undeleteStation)
};

export const resetUndeleteStation = (state) => {
  state.undeleteStation = {};
};


export const reset = (state) => {
  const s = initialState();

  Object.keys(s).forEach((key) => {
    state[key] = s[key];
  });
};
