import { config } from "/app.config"

export const PINGPARROT_CHAT_ID = process.env.VUE_APP_PINGPARROT_CHAT_ID

export const isPingParrotEnabled = () => {
  return PINGPARROT_CHAT_ID && PINGPARROT_CHAT_ID.length && config.isZeno
}

export const initializePingParrot = () => {
  if (!isPingParrotEnabled() || document.getElementById("bubble-btn")) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("src", "https://cdn.pingparrot.com/scripts/chatbot.js")
  script.setAttribute("data-pingparrot-chatid", PINGPARROT_CHAT_ID)
  script.setAttribute("async", true)

  document.head.appendChild(script)
}
