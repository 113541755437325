import toolsClient from "@/api/toolsClient";

const search = async (query = "", limit = 50, offset = 0) => {
  const { data } = await toolsClient.get(
    `stations/search/?limit=${limit}&offset=${offset}&search=${query}`
  );

  return data;
};

const list = async (pageNumber = 0 , pageSize = 10, sortBy = 'name', sortDesc = false, search) => {
  let path = `stations/list?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}`
  if (search){
    path += `&search=${search}`
  }
  const { data } = await toolsClient.get(path);

  return data;
};


const get = async (id) => {
  const { data } = await toolsClient.get(`stations/${id}`);

  return data;
};

const create = async (accountId, stationData) => {
  const { data } = await toolsClient.post(
    `accounts/${accountId}/stations/`,
    stationData
  );

  return data;
};

const update = async (stationData) => {
  const { data } = await toolsClient.put(
    `stations/${stationData.key}`,
    stationData
  );

  return data;
};

const suspend = async (id) => {
  const { data } = await toolsClient.put(`stations/${id}/suspend`);

  return data;
};

const reactivate = async (id) => {
  const { data } = await toolsClient.put(`stations/${id}/reactivate`);

  return data;
};

const destroy = async (id) => {
  const { data } = await toolsClient.delete(`stations/${id}`);

  return data;
};

const getProfile = async (id) => {
  const { data } = await toolsClient.get(`stations/${id}/profile`);

  return data;
};

const updateProfile = async (id, profileData) => {
  const { data } = await toolsClient.put(`stations/${id}/profile`, profileData);

  return data;
};

const getMasterLink = async (id) => {
  const response = await toolsClient.get(`stations/${id}/master_link/`);

  return response.data;
};

const updateMasterLink = async (id, linkData) => {
  const { data } = await toolsClient.put(
    `stations/${id}/master_link/`,
    linkData
  );

  return data;
};

const resetMasterLink = async (id) => {
  const { data } = await toolsClient.post(`stations/${id}/master_link/reset`);

  return data;
};

const updateSlug = async (id, slug) => {
  await toolsClient.put(`stations/${id}/pretty_url`, {
    pretty_url: slug,
  });
};

const changeAccount = async (id, accountId) => {
  await toolsClient.post(`stations/${id}/account/${accountId}`);
};

export default {
  search,
  list,
  get,
  create,
  update,
  destroy,
  getProfile,
  updateProfile,
  getMasterLink,
  updateMasterLink,
  resetMasterLink,
  updateSlug,
  changeAccount,
  suspend,
  reactivate
};
