import toolsClient from "@/api/toolsClient"
import axios from "axios"

const LOCAL_STORAGE_TOKEN_KEY = "mediaLibraryUploaderToken"
const API_ENDPOINTS = {
  MEDIA: "/api/media/",
  UPLOAD: "/upload/",
  TASK: "/task/",
}

let libraryClient = null
let uploaderClient = null

const getToken = () => localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)
const setToken = (token) => localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token)

const refreshTokenData = async (stationKey) => {
  try {
    const response = await toolsClient.get(`stations/${stationKey}/library_token/`)
    const tokenData = response.data
    setToken(tokenData.token)
    return tokenData
  } catch (e) {
    console.error("Error refreshing media library token:", e)
    throw e
  }
}

const createAxiosInstance = (stationKey, baseURL) => {
  const instance = axios.create({ baseURL })

  instance.interceptors.request.use(
    (config) => {
      const token = getToken()
      if (token) {
        config.headers["X-Auth-Token"] = token
      }
      return config
    },
    (e) => Promise.reject(e)
  )

  instance.interceptors.response.use(
    (response) => response,
    async (e) => {
      const originalRequest = e.config
      if (e.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        try {
          const { token } = await refreshTokenData(stationKey)
          originalRequest.headers["X-Auth-Token"] = token
          if (originalRequest.url.startsWith(API_ENDPOINTS.UPLOAD)) {
            originalRequest.url = `${API_ENDPOINTS.UPLOAD}?token=${token}&folder=default`
          }
          return instance(originalRequest)
        } catch (refreshe) {
          return Promise.reject(refreshe)
        }
      }
      return Promise.reject(e)
    }
  )

  return instance
}

export const getLibrary = async (stationKey) => {
  try {
    const tokenData = await refreshTokenData(stationKey)
    libraryClient = createAxiosInstance(stationKey, tokenData.url)
    uploaderClient = createAxiosInstance(stationKey, tokenData.uploader)
    return tokenData
  } catch (e) {
    console.error("Error getting media library:", e)
    throw e
  }
}

export const searchMedia = async ({ search = "", offset = 0, limit = 10 }) => {
  try {
    const params = new URLSearchParams({ offset, limit, ...(search && { search }) })
    const endpoint = search ? `${API_ENDPOINTS.MEDIA}search` : API_ENDPOINTS.MEDIA
    const response = await libraryClient.get(`${endpoint}?${params}`)
    return response.data
  } catch (e) {
    console.error("Error searching media:", e)
    throw e
  }
}

export const uploadMediaFile = async (file, config) => {
  try {
    const data = new FormData()
    data.append("name", file.name)
    data.append("file", file)
    const token = getToken()
    const response = await uploaderClient.post(`${API_ENDPOINTS.UPLOAD}?token=${token}&folder=default`, data, config)
    return response.data
  } catch (e) {
    console.error("Error uploading media file:", e)
    throw e
  }
}

export const getMediaUploadStatus = async (task) => {
  try {
    const response = await uploaderClient.get(`${API_ENDPOINTS.TASK}${task}`)
    return response.data
  } catch (e) {
    console.error("Error getting media upload status:", e)
    throw e
  }
}

export const updateMedia = async (media) => {
  try {
    const response = await libraryClient.put(`${API_ENDPOINTS.MEDIA}${media.key}`, {
      title: media.metadata.title,
      artist: media.metadata.artist,
      album: media.metadata.album,
    })
    return response.data
  } catch (e) {
    console.error("Error updating media file:", e)
    throw e
  }
}

export { libraryClient, uploaderClient }
