import toolsClient from "@/api/toolsClient"

const get = async () => {
  const { data } = await toolsClient.get("users/me")

  return data
}

const update = async (payload) => {
  const { data } = await toolsClient.put("users/me", payload)

  return data
}

const updatePassword = async (password) => {
  let formData = new FormData()
  formData.set("value", password)

  await toolsClient.put("users/me/password/", formData)
}

const getProfile = async () => {
  const { data } = await toolsClient.get("auth/profile")

  return data
}

const updateProfile = async (payload) => {
  const { data } = await toolsClient.post("auth/profile", payload)

  return data
}

const getAccounts = async () => {
  const { data } = await toolsClient.get("users/me/accounts/")

  return data
}

const getStations = async () => {
  const { data } = await toolsClient.get("users/me/stations/")

  return data
}

// posts
const getPosts = async (accountKey) => {
  const { data } = await toolsClient.get("users/portal-posts/" + accountKey)
  return data
}

const updatePost = async (postKey) => {
  const { data } = await toolsClient.put("users/portal-posts/seen/" + postKey)
  return data
}

export default {
  get,
  update,
  updatePassword,
  getProfile,
  updateProfile,
  getAccounts,
  getStations,
  getPosts,
  updatePost
}
