import { Buffer } from "buffer";
import { STATION_TYPE_MASTER } from "@/store/modules/accountStations/state";
import { config } from "/app.config";

export const getStationType = (typeID) => {
  if (typeID === 8 || typeID === 40) {
    return "streaming";
  } else if (typeID === 12) {
    return "playlist";
  } else if (typeID === 47) {
    return "full";
  } else if (typeID >= 64) {
    return "relay";
  }

  return "";
};

export const localeNum = (number, decPlaces) => {
  if (typeof decPlaces === "undefined") {
    decPlaces = 2;
  }

  return parseFloat(number).toLocaleString(undefined, {
    minimumFractionDigits: decPlaces,
    maximumFractionDigits: decPlaces,
  });
};

export const bytesToSize = (bytes) => {
  let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (!bytes) return "0 Byte";

  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const formatBytes = (a, b) => {
  if (!a) {
    return "0 Bytes";
  }

  let c = 1024,
    d = b || 2,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};

export const url = (path) => {
  return process.env.VUE_APP_BASE_URL + path;
};

export const stationTransformer = () => {
  return (data) => {
    return {
      key: data.key,
      name: data.name,
      slug: data.pretty_url,
      account: data.billing_owner,
      logo: getOptimizedImage(
        process.env.VUE_APP_TOOLS_SERVICE_URL +
          `/content/stations/${data.key}/image/?u=` +
          Date.now(),
        170,
        170,
      ),
      streamName: data.streamname,
      type: data.station_type,
      isMaster: data.station_type === STATION_TYPE_MASTER,
      raw: data,
    };
  };
};

export const getOptimizedImage = (imageURL, width = 270, height = 270) => {
  if (typeof imageURL === "undefined" || !imageURL.length) {
    return null;
  }

  const createHmac = require("create-hmac");

  const KEY =
    "c00eca2bc3a3f7c19b50882db2c7efba9dc87d83c899c3c2bd557d1983f916dfff339f503d9ae849e3c37c1da9c12833d4ff01ce60c0e4906bf671f47438695e";
  const SALT =
    "d681a205b59b687c29d27f2738d893dc714b7c797ed2c09068d5389d8c6f052f5356adee520176950042fd2aeca3561a988805bc8fdda823e847dc1c78454e45";

  const urlSafeBase64 = (string) => {
    return Buffer.from(string)
      .toString("base64")
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  };

  const hexDecode = (hex) => Buffer.from(hex, "hex");

  const sign = (salt, target, secret) => {
    const hmac = createHmac("sha256", hexDecode(secret));
    hmac.update(hexDecode(salt));
    hmac.update(target);
    return urlSafeBase64(hmac.digest());
  };

  const extension = "webp";
  const encoded_url = urlSafeBase64(imageURL);
  const path = `/rs:fit:${width}:${height}/g:ce:0:0/${encoded_url}.${extension}`;

  const signature = sign(SALT, path, KEY);

  return config.imageProxyURL + signature + path;
};

export const isProduction = () => {
  return process.env.NODE_ENV === "production";
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const sec2time = (timeInSeconds) => {
  timeInSeconds = parseInt(timeInSeconds);

  if (timeInSeconds < 0) {
    timeInSeconds = 0;
  }

  let pad = function (num, size) {
      return ("000" + num).slice(size * -1);
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60);

  let timeString = "";

  if (hours) {
    timeString += pad(hours, 2) + ":";
  }

  return timeString + pad(minutes, 2) + ":" + pad(seconds, 2);
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    // Clear the previous timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Set a new timeout
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};
