import * as api from "@/api/portalPosts.api";

export const fetchPortalPosts = async ({ commit }) => {
  commit("setPosts", await api.getAll());
};

export const createPortalPost = async ({ commit }, data) => {
  commit("setPost",  await api.create(data));
};

export const getPortalPost = async ({ commit }, id) => {
  commit("setPost",  await api.get(id));
};

export const updatePortalPost = async (vuex, data) => {
  await api.update(data);
};

export const removePortalPost = async (vuex, id) => {
  await api.remove(id);
};

export const resetPortalPost = async (context) => {
  context.commit("resetPost");
};

export const resetPortalPosts = async (context) => {
  context.commit("reset");
};
