import {initialState} from "./state"

export const setAccounts = (state, payload) => {
  state.accounts = payload
}

export const setAccount = (state, payload) => {
  state.account = payload
}

export const reset = (state) => {
  const s = initialState()

  Object.keys(s).forEach(key => {
    if (key !== "accounts") {
      state[key] = s[key]
    }
  })
}
