import * as api from "@/api/stationRelays.api";
import StationService from "@/services/StationService";

export const getRelays = async ({ commit }, stationKey) => {
  let relays = await api.getRelays(stationKey);

  commit("setRelays", relays);

  return relays;
};

export const getRelay = async ({ commit }, id) => {
  const relay = await StationService.getStation(id);

  commit("setRelay", relay);

  return relay;
};
