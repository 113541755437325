function initialState() {
  return {
    masterLink: {
      encoding: "",
      failover_settings: [],
      linkURI: "",
      masterLinkMountPoint: "",
      masterLinkPassword: "",
      streamId: "",
      relaySourceURI: "",
    },

    streamConfig: {
      allow_secure: false,
      bitrate: 0,
      burst: 0,
      fingerprint_category: "",
      media_type: "",
      mp4: false,
      sample_rate: 0,
    },

    station: {
      exclusive: false,
      activation_status: 0,
      station_service_type: 0,
      searchable: false,
      pretty_url: "",
      activated: null,
      server_status: {
        status: 0,
        live: true,
        radio_server_status: 0,
      },
      environment: 0,
      library_guid: null,
      type: 0,
      public: false,
      updated: "",
      description: "",
      show_tracks_playing: true,
      tags: [],
      organization_size: 0,
      reports_settings: 0,
      default_type: 0,
      default_timezone: "",
      key: "",
      streambase: "",
      radio_server_settings: {
        relay_url: null,
        call_in_allowed: false,
        failover_settings: [],
        interrupt_master: null,
        burst: 0,
        allow_secure: false,
        fingerprint_category: null,
        mix_offset: 0,
        studio_allowed: false,
        mp4: false,
        sample_rate: 0,
        project_type: 0,
        media_type: "",
        mix_speed: 0,
        bitrate: 0,
        metadata: "",
      },
      name: "",
      created: "",
      country: "",
      streamname: "",
      suspended: false,
      deleted: false,
      zosa: false,
      organization_type: 0,
    },

    stationCategories: [
      {
        value: 36,
        caption: "Children",
      },
      {
        value: 39,
        caption: "College/University",
      },
      {
        value: 37,
        caption: "Comedy",
      },
      {
        value: 38,
        caption: "General",
      },
      {
        value: 30,
        caption: "Music",
      },
      {
        value: 31,
        caption: "News/Talk",
      },
      {
        value: 33,
        caption: "Religious",
      },
      {
        value: 32,
        caption: "Sports",
      },
    ],

    organizationTypes: [
      {
        value: 1,
        caption: "Professional internet radio only",
      },
      {
        value: 2,
        caption: "FM/AM",
      },
      {
        value: 3,
        caption: "Community",
      },
      {
        value: 4,
        caption: "College/School",
      },
      {
        value: 5,
        caption: "NGO",
      },
      {
        value: 6,
        caption: "Hotel",
      },
      {
        value: 7,
        caption: "Retail",
      },
      {
        value: 8,
        caption: "Radio/Music Personality",
      },
      {
        value: 9,
        caption: "Media Company",
      },
      {
        value: 10,
        caption: "Other business",
      },
    ],

    organizationSizes: [
      {
        value: 1,
        caption: "< 5 people",
      },
      {
        value: 2,
        caption: "5-10 people",
      },
      {
        value: 3,
        caption: "10-50 people",
      },
      {
        value: 4,
        caption: "> 50 people",
      },
    ],
  };
}

let state = initialState();

export { initialState, state };
