import * as api from "@/api/stationLibrary.api"
import router from "@/router"
import { addPlaylistTrack } from "@/api/stationAutoDj.api"

export const addToUploadQueue = async ({ commit }, mediaList) => {
  commit("addToUploadQueue", mediaList)
}

export const uploadNext = async ({ dispatch, getters }) => {
  if (getters.pendingUploads.length > 0) {
    dispatch("uploadMedia", { ...getters.pendingUploads[0] })
  }
}

export const uploadMedia = async ({ commit, dispatch }, media) => {
  const controller = new AbortController()
  commit("setUploadAbortController", controller)

  commit("updateQueuedMedia", {
    media,
    fields: { status: "uploading", progress: 0 },
  })

  try {
    const config = {
      signal: controller.signal,
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)

        commit("updateQueuedMedia", {
          media,
          fields: { progress: percentCompleted },
        })
      },
    }

    const response = await api.uploadMediaFile(media.file, config)

    commit("updateQueuedMedia", {
      media,
      fields: { status: response.status.toLowerCase(), task: response.task_id, progress: 0 },
    })

    media.task = response.task_id
    media.status = response.status.toLowerCase()

    setTimeout(() => {
      dispatch("checkMediaUploadStatus", media)
    }, 1000)

    dispatch("uploadNext")
  } catch (e) {
    if (e?.message === "canceled") {
      commit("updateQueuedMedia", {
        media,
        fields: { status: "canceled", progress: null },
      })
    } else {
      console.log(e)

      commit("updateQueuedMedia", {
        media,
        fields: { status: "error", progress: null },
      })
    }
  }
}

export const checkMediaUploadStatus = async ({ commit, dispatch }, media) => {
  try {
    const response = await api.getMediaUploadStatus(media.task)

    switch (response.status) {
      case "SUCCESS": {
        commit("updateQueuedMedia", {
          media,
          fields: {
            status: "success",
            progress: null,
            data: response.data,
          },
        })

        const result = await addPlaylistTrack(router.currentRoute.params.stationKey, response.data.key)

        dispatch("Autodj/addPlaylistReferences", [result.reference], { root: true })

        break
      }
      case "PROCESSING": {
        commit("updateQueuedMedia", {
          media,
          fields: {
            status: "processing",
            progress: response.progress,
          },
        })

        setTimeout(() => {
          dispatch("checkMediaUploadStatus", media)
        }, 1000)

        break
      }
      case "ERROR": {
        commit("updateQueuedMedia", {
          media,
          fields: {
            status: "error",
            progress: null,
          },
        })

        break
      }
    }
  } catch (e) {
    console.log(e)

    commit("updateQueuedMedia", {
      media,
      fields: {
        status: "error",
        progress: null,
      },
    })
  }
}

export const abortController = async ({ state, commit }) => {
  if (state.uploadAbortController) {
    state.uploadAbortController.abort()
    commit("setUploadAbortController", null)
  }
}

export const cancelUpload = async ({ commit, dispatch }) => {
  dispatch("abortController")
  commit("setUploadQueue", [])
}

export const cancelMediaUpload = async ({ commit, dispatch }, media) => {
  if (media.status === "uploading") {
    dispatch("abortController")
  }

  commit("updateQueuedMedia", {
    media,
    fields: {
      status: "canceled",
      progress: null,
    },
  })
}

export const resetLibrary = async ({ commit }) => {
  commit("reset")
}
