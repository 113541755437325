function initialState() {
  return {
    post: {
      startSending: null,
      stopSending: null,
      publishDate: null,
      title: "",
      contentHtml: "",
      format: "large",
      imageTargetUrl: null,
      rules: {
        browserLanguage: {
          isNegated: false,
          values: [],
        },
        plans: {
          isNegated: false,
          values: ["FREE"],
        },
      },
    },
    posts: [],
  }
}

let state = initialState()

export {
  initialState,
  state
}
