import { initialState } from "./state";

export const setParams = (state, payload) => {
  state.params = payload;
};

export const setPeriodStats = (state, payload) => {
  state.periodStats = payload;
};

export const setDeviceStats = (state, payload) => {
  state.deviceStats = payload;
};

export const setRegionalStats = (state, payload) => {
  state.regionalStats = payload;
};

export const setRoyaltyStats = (state, payload) => {
  state.royaltyStats = payload;
};

export const setPeriodType = (state, payload) => {
  state.periodType = payload;
};

export const setCityStats = (state, payload) => {
  state.cityStats = payload;
};

export const reset = (state) => {
  const s = initialState();

  Object.keys(s).forEach((key) => {
    state[key] = s[key];
  });
};
