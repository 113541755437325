import {
  deletePodcastRecordingSchedule,
  getPodcastRecordingScheduleList,
} from "@/api/podcastRecordingSchedule.api";

export const fetchSchedule = async ({ commit }, podcastKey) => {
  try {
    let result = await getPodcastRecordingScheduleList(podcastKey);

    if (result.length) {
      commit("setSchedule", result[0]);
    } else {
      commit("reset");
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const deleteSchedule = async ({ commit }, { podcastKey, id }) => {
  try {
    await deletePodcastRecordingSchedule(podcastKey, id);

    commit("reset");
  } catch (e) {
    console.log(e);
    throw e;
  }
};
