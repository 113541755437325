function initialState() {
  return {
    relays: [],

    relay: {
      exclusive: false,
      streamname: "",
      searchable: false,
      pretty_url: "",
      billing_owner: "",
      activated: "",
      rights: [],
      server_status: {
        status: 1,
        live: false,
        radio_server_status: 1
      },
      environment: 1,
      monitoring_settings: {
        link: false, emails: []
      },
      library_guid: null,
      type: 2,
      public: false,
      updated: "",
      description: "",
      show_tracks_playing: true,
      tags: [],
      organization_size: 0,
      reports_settings: 1,
      default_type: 1,
      default_timezone: "",
      statistics: {
        station_shows_count: 0,
        plan_people: -1,
        shows_count: 0,
        djs_count: 0,
        plan_shows: -1,
        station_people: 0,
        followed_score: 0
      },
      key: "",
      streambase: "",
      radio_server_settings: {
        relay_url: "",
        call_in_allowed: false,
        failover_settings: [],
        interrupt_master: null,
        burst: 5000,
        allow_secure: false,
        fingerprint_category: null,
        mp4: false,
        studio_allowed: false,
        mix_offset: 550,
        sample_rate: 44100,
        project_type: 2,
        media_type: "MP3",
        mix_speed: 300,
        bitrate: 128000,
        metadata: ""
      },
      name: "",
      created: "",
      country: "",
      station_service_type: 72,
      activation_status: 4,
      zosa: false,
      organization_type: 0
    },

    relayTags: ["web", "call", "app", "tunein", "other"],
  }
}

let state = initialState()

export {
  initialState,
  state
}
