import {
  getStationPeople,
  getStationPeopleInvites,
} from "@/services/PeopleService";

export const fetchStationPeople = async ({ commit }, stationKey) => {
  const people = await getStationPeople(stationKey);

  commit("setPeople", people);
};

export const fetchStationPeopleInvites = async ({ commit }, stationKey) => {
  const invites = await getStationPeopleInvites(stationKey);

  commit("setInvites", invites);
};
