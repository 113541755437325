function initialState() {
  return {
    audioAdsSettings: {
      hasAudioAds: null,
      applyToRelays: null,
    },
    audioAdsAgreement: null,
  }
}

let state = initialState()

export { initialState, state }
