import { config } from "/app.config";

export default config.isZeno
  ? [
      {
        path: "partners",
        component: () => import("@/views/Portal/PortalPartners/Partners"),
        children: [
          {
            path: "",
            name: "portal.partners",
            component: () =>
              import("@/views/Portal/PortalPartners/PartnersList"),
            meta: {
              pageTitle: "Partners",
              rule: "portalAdmin",
            },
          },
          {
            path: "create",
            component: () =>
              import("@/views/Portal/PortalPartners/PartnerCreate"),
            name: "portal.partners.create",
            meta: {
              pageTitle: "Add partner",
              parent: "portal.partners",
              rule: "portalAdmin",
            },
          },
          {
            path: ":partnerSlug",
            component: () => import("@/views/Portal/PortalPartners/Partner"),
            name: "portal.partner",
            meta: {
              pageTitle: "Partner info",
              parent: "portal.partners",
              rule: "owner",
            },
            children: [
              {
                name: "portal.partner.edit",
                path: "edit",
                component: () =>
                  import("@/views/Portal/PortalPartners/PartnerEdit"),
                meta: {
                  pageTitle: "Edit partner",
                  parent: "portal.partners",
                  icon: "InfoIcon",
                  rule: "owner",
                },
              },
            ],
          },
        ],
      },
    ]
  : [];
