import router from "@/router"

/**
 * ================================
 * STATIONS
 * ================================
 */
export const station = (state) => {
  return state.station
}

export const isStationSuspended = (state) => {
  return state.station.suspended
}

export const stationKey = (state) => {
  if (state.station.key) {
    return state.station.key
  } else if (router.currentRoute.params.stationKey) {
    return router.currentRoute.params.stationKey
  }

  return ""
}

export const activeStation = (state) => {
  if (Object.prototype.hasOwnProperty.call(state.station, "server_status")) {
    return !!state.station.server_status.status
  }
}

export const masterLink = (state) => {
  return state.masterLink
}

export const masterLinkEnabled = (state) => {
  return state.masterLink && {}.hasOwnProperty.call(state.masterLink, "relaySourceURI")
}

export const streamConfig = (state) => {
  return state.streamConfig
}

export const logo = (state) => {
  if (state.station.key) {
    return process.env.VUE_APP_TOOLS_SERVICE_URL + `/content/stations/${state.station.key}/image/?updated=${Date.now()}`
  }

  return ""
}

export const background = (state) => {
  if (state.station.key) {
    return (
      process.env.VUE_APP_TOOLS_SERVICE_URL +
      `/content/stations/${state.station.key}/microsite/background_image/?keep=w&updated=${Date.now()}`
    )
  }

  return ""
}

/**
 * ================================
 * OTHER
 * ================================
 */
export const stationCategories = (state) => {
  return state.stationCategories
}

export const organizationTypes = (state) => {
  return state.organizationTypes
}

export const organizationSizes = (state) => {
  return state.organizationSizes
}

export const genreMusic = () => {
  return 30
}

export const genreGeneral = () => {
  return 38
}
