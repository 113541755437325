import peopleApi from "@/api/people.api";

const formatInvitedPeople = (people) => {
  return people.map((user) => {
    return {
      avatar: "",
      email: user.invitedEmail,
      fullName:
        user.invitedUserFirstName && user.invitedUserLastName
          ? user.invitedUserFirstName + " " + user.invitedUserLastName
          : "",
      id: "",
      inviteToken: user.token,
      roles: ["invited " + user.role],
    };
  });
};

const formatPeople = (people) => {
  return people.map((user) => {
    return {
      id: user.id,
      avatar: process.env.VUE_APP_TOOLS_SERVICE_URL + user.dj_info.image_url,
      fullName: user.basic_info.first_name + " " + user.basic_info.last_name,
      email: user.basic_info.email,
      roles: user.rights,
    };
  });
};

export const getStationPeople = async (id) => {
  let { staff = [] } = await peopleApi.list("stations", id);

  return formatPeople(staff);
};

export const getAccountPeople = async (id) => {
  let { admins = [] } = await peopleApi.list("accounts", id);

  return formatPeople(admins);
};

export const getPortalPeople = async () => {
  let { admins = [] } = await peopleApi.list();

  return formatPeople(admins);
};

export const getStationPeopleInvites = async (id) => {
  const people = await peopleApi.getInvited("stations", id);

  return formatInvitedPeople(people);
};

export const getAccountPeopleInvites = async (id) => {
  const people = await peopleApi.getInvited("accounts", id);

  return formatInvitedPeople(people);
};

export const removeAccountUser = async (id, user) => {
  await peopleApi.removeRole(user, "admins", "accounts", id);
};

export const removeStationUser = async (id, user) => {
  await peopleApi.removeRole(user, "staff", "stations", id);
};

export const removePortalUser = async (user) => {
  await peopleApi.removeRole(user, "admins");
};

export const inviteAccountUser = async (id, email) => {
  await peopleApi.sendInvite(email, "admins", "accounts", id);
};

export const inviteStationUser = async (id, email) => {
  await peopleApi.sendInvite(email, "staff", "stations", id);
};

export const invitePortalUser = async (email) => {
  await peopleApi.sendInvite(email, "admins");
};

export const cancelStationUserInvite = async (id, token) => {
  await peopleApi.cancelInvite(token, "stations", id);
};

export const cancelAccountUserInvite = async (id, token) => {
  await peopleApi.cancelInvite(token, "accounts", id);
};

export default {
  getAccountPeople,
  getAccountPeopleInvites,
  getStationPeople,
  getStationPeopleInvites,
  getPortalPeople,
  removeAccountUser,
  removeStationUser,
  removePortalUser,
  inviteAccountUser,
  inviteStationUser,
  invitePortalUser,
  cancelStationUserInvite,
  cancelAccountUserInvite,
};
